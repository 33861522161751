import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Initial } from 'react-initial'

// window.moment = moment

// const getUTCTime = (time) => {
//   let utcTime = time
//   if (time != null && time.length > 0 && time.indexOf('Z') === -1) {
//     utcTime = `${time}Z`
//   }
//   return utcTime
// }

export function getHighlightedText(str, highlightText) {
  const txt = str != null ? str : ''
  if (highlightText == null || highlightText.length === 0) {
    return str
  }

  const lowertxt = txt.toLowerCase()
  const lowerHtxt = highlightText.toLowerCase()
  const pos = lowertxt.indexOf(lowerHtxt)

  if (pos >= 0) {
    const preText = txt.substring(0, pos)
    const hText = txt.substring(pos, pos + highlightText.length)
    const nextText = txt.substring(pos + highlightText.length)

    return (
      <span>
        {preText}
        <span className="highlight">{hText}</span>
        {nextText}
      </span>
    )
  }

  return <span>{str}</span>
}

export const colors = [
  '#A9624A',
  '#D06B64',
  '#F83A22',
  '#FA573C',
  '#FF7537',
  '#FF950F',
  '#00BB65',
  '#008247',
  '#43A608',
  '#6BBC00',
  '#FFA600',
  '#FFBC0A',
  '#67AC8F',
  '#61BCC4',
  '#7AA4C8',
  '#4986E7',
  '#6D6FDE',
  '#8562D6',
  '#8D8D8D',
  '#AB8E92',
  '#A7767D',
  '#F46594',
  '#F46594',
  '#7446B7',
]

export function getInitialAvatar(name, email) {
  if (name == null && email == null) {
    return null
  }

  const avatarProps = {
    // charCount: 2,
    charCount: 1,
  }

  let tmp = []
  if (name != null) {
    tmp = name.split(/[\s\r\n,.]+/gm)
  } else {
    const pos = email.indexOf('@')
    tmp = email.substring(0, pos - 1).split(/[\s\r\n,.]+/gm)
    // const eText = email.substring(pos);
  }

  let str = ''
  tmp.forEach((txt) => {
    if (/^(\p{L}|\d)/gu.test(txt) === true) {
      str += txt.charAt(0)
    }
  })

  str = str.toUpperCase()

  avatarProps.name = str

  const seed = 0
  avatarProps.color = colors[Math.floor((str.charCodeAt(0) + seed) % colors.length)]

  return <Initial {...avatarProps} />
}

function unicodeCharAt(str, index) {
  const first = str.charCodeAt(index)
  let second

  if (first >= 0xd800 && first <= 0xdbff && str.length > index + 1) {
    second = str.charCodeAt(index + 1)

    if (second >= 0xdc00 && second <= 0xdfff) {
      return str.substring(index, index + 2)
    }
  }

  return str[index]
}

function unicodeSlice(str, start, end, words) {
  let accumulator = ''
  let character
  let stringIndex = 0
  let unicodeIndex = 0
  let nextSpace = -1
  const { length } = str

  // Remove any leading/trailing spaces
  str = str.trim()

  while (stringIndex < length) {
    character = unicodeCharAt(str, stringIndex)

    if (unicodeIndex >= start && unicodeIndex < end) {
      accumulator += character
    } else {
      break
    }

    stringIndex += character.length
    unicodeIndex += 1

    // Find the next space offset from the previous finding
    nextSpace = words ? str.indexOf(' ', nextSpace + 1) : -1
    stringIndex = nextSpace > 0 ? nextSpace + 1 : stringIndex
  }

  return accumulator
}

export function getInitialAvatarSrcByProps(props) {
  const ownProps = {
    name: 'Name',
    color: null,
    seed: 0,
    charCount: 1,
    textColor: '#ffffff',
    height: 100,
    width: 100,
    fontSize: 60,
    fontWeight: 400,
    fontFamily:
      'HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif',
    radius: 0,
    ...props,
  }

  const {
    width,
    height,
    textColor,
    fontFamily,
    fontSize,
    fontWeight,
    charCount,
    useWords,
    name,
    color,
    seed,
    radius: borderRadius,
  } = ownProps

  let initial = unicodeSlice(name || 'Name', 0, charCount || 1, useWords || false).toUpperCase()
  let backgroundColor =
    color !== null ? color : colors[Math.floor((initial.charCodeAt(0) + seed) % colors.length)]

  if (name.length === 0) {
    initial = ''
    ;[backgroundColor] = colors
  }

  const InitialSvg = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      pointerEvents="none"
      {...{
        width,
        height,
        style: {
          width,
          height,
          backgroundColor,
          borderRadius,
        },
      }}
    >
      <text
        y="50%"
        x="50%"
        dy="0.35em"
        pointerEvents="auto"
        fill={textColor}
        fontFamily={fontFamily}
        textAnchor="middle"
        style={{ fontSize, fontWeight }}
      >
        {initial}
      </text>
    </svg>
  )

  const encodedStr = encodeURIComponent(renderToStaticMarkup(<InitialSvg />)).replace(
    /%([0-9A-F]{2})/g,
    (match, p1) => {
      return String.fromCharCode(`0x${p1}`)
    },
  )

  const imgStr = window.btoa(encodedStr)
  // const imgStr = window.btoa(unescape(encodeURIComponent(renderToStaticMarkup(<InitialSvg />))));
  const svgHtml = `data:image/svg+xml;base64,${imgStr}`

  return svgHtml
}

export function getInitialAvatarSrc(name, email, props) {
  if (name == null && email == null) {
    return null
  }

  const avatarProps = {
    ...props,
    // charCount: 2,
    charCount: 1,
  }

  let tmp = []
  if (name != null) {
    tmp = name.split(/[\s\r\n,.]+/gm)
  } else {
    const pos = email.indexOf('@')
    tmp = email.substring(0, pos - 1).split(/[\s\r\n,.]+/gm)
    // const eText = email.substring(pos);
  }

  let str = ''
  tmp.forEach((txt) => {
    if (/^(\p{L}|\d)/gu.test(txt) === true) {
      str += txt.charAt(0)
    }
  })

  avatarProps.name = str

  return getInitialAvatarSrcByProps(avatarProps)
}

const cUtil = {
  getInitialAvatar,
}

export default cUtil
