import React from 'react'
import { List, Dropdown, Button, Spin } from 'antd'
// import { EllipsisOutlined } from '@ant-design/icons'
import FTIcon from 'components/FTIcon'

import classNames from 'classnames'
import dataMgr from 'pages/chat/component/chatView/data/dataManager'

import { timeFormat, formatBytes } from 'utils'

import downloadSVG from 'assets/images/button-download.svg'
import menuHSVG from 'assets/images/button-menu-h.svg'

import styles from '../style.module.scss'

class FileItemDef extends React.Component {
  state = {}

  componentDidMount() {
    const { attachment, chatObj } = this.props

    attachment.reactMgr.addReactObj(this)

    attachment.loadThumbFile(chatObj)
  }

  componentWillUnmount() {
    const { attachment } = this.props

    attachment.reactMgr.removeReactObj(this)
  }

  setFileElem = (ref) => {
    if (ref != null) {
      // console.log(' ---- ref ---- ', ref, ref.innerText)
      const elems = ref.querySelectorAll('span')
      if (elems.length > 0) {
        setTimeout(() => {
          // const w = elems[1].offsetWidth // + 1
          let w = elems[1].offsetWidth // + 1;
          const wStr = window.getComputedStyle(elems[1], null).width
          if (wStr.substring(wStr.length - 2) === 'px') {
            const num = wStr.substring(0, wStr.length - 2)
            w = window.parseFloat(num)
          }
          if (ref.offsetWidth > w) {
            elems[1].style.maxWidth = `${w}px`
            elems[0].style.maxWidth = `calc(100% - ${w}px)`
          }
        }, 100)
      }
    }
  }

  onMenuClick = (key, attachment) => {
    if (key === 'download') {
      // fileUtil.confirmDownload(chatObj, item.file_digest, item.file_name, item.file_size);
      this.download(attachment)
    } else if (key === 'goToMessage') {
      console.log(' go to message. ')
    } else if (key === 'test') {
      console.log(' -- ', this.helperElem)
    }
  }

  download = (attachment) => {
    const { chatObj, fileUtil } = this.props
    const { origin: item, filetype, fileurl } = attachment.info

    if (filetype === 'link') {
      console.log(' --- link - ', fileurl)
      window.open(fileurl, '_blank')
    } else {
      fileUtil.showDownload(chatObj, item.file_digest, item.file_name, item.file_size)
    }
  }

  render = () => {
    const { thumbUrl, thumbLoading } = this.state

    const { attachment, itemMenuFunc } = this.props

    if (attachment == null) {
      return ''
    }

    const { origin: item, filetype, storageprovider } = attachment.info

    const memberMap = dataMgr.globalMemberMap

    const downloadBtn = (
      <Button
        className={classNames('downloadBtn')}
        type="text"
        icon={<img src={downloadSVG} alt="download" />}
        htmlType="button"
        onClick={() => {
          this.onMenuClick('download', attachment)
        }}
      />
    )

    const menu = itemMenuFunc != null ? itemMenuFunc(item) : null
    // {
    //   items: [
    //     // {
    //     //   label: 'Open',
    //     //   key: 'open',
    //     // },
    //     // {
    //     //   label: 'Copy link',
    //     //   key: 'copy',
    //     // },
    //     // {
    //     //   label: 'Download',
    //     //   key: 'download',
    //     // },
    //     {
    //       label: 'Go to message',
    //       key: 'goToMessage',
    //     },
    //     // {
    //     //   label: 'test',
    //     //   key: 'test',
    //     // },
    //   ],
    //   mode: 'vertical',
    //   onClick: ({ key }) => {
    //     this.onMenuClick(key, item)
    //   },
    // }

    let dropdownElem = ''

    if (menu != null && menu.items != null && menu.items.length > 0) {
      menu.mode = 'vertical'

      const dropdownAttrs = {
        menu,
        overlayClassName: classNames('floatingSubMenu', 'menuStyle1'),
        trigger: ['click'],
      }

      dropdownElem = (
        <Dropdown {...dropdownAttrs}>
          <Button type="text" icon={<img src={menuHSVG} alt="menu" />} htmlType="button" />
        </Dropdown>
      )
    }

    const txt = item.file_name

    let pos = txt.lastIndexOf('.')

    if (pos < 0) {
      pos = txt.length
    }

    const front = txt.substring(0, pos)
    const end = txt.substring(pos)

    const filenameElem = (
      <div
        ref={(ref) => {
          this.setFileElem(ref)
        }}
        className={classNames(styles.fileName)}
      >
        <span className={styles.front}>{front}</span>
        <span className={styles.end}>{end}</span>
      </div>
    )

    const dateStr = timeFormat(item.create_time, 'MMM D a h:mm')

    const owner = memberMap[item.sender] != null ? memberMap[item.sender].display_name : item.sender

    const descElem = (
      <div className={styles.fileDesc}>
        <span>{dateStr}</span>&nbsp;&#x2022;&nbsp;
        <span>{formatBytes(item.file_size, 0)}</span>&nbsp;&#x2022;&nbsp;
        <span>{owner}</span>
      </div>
    )

    let fileIcon = null

    if (filetype === 'link') {
      fileIcon = (
        <div className={styles.fileIcon}>
          <img
            src="https://rework.so/resources/fileicon/online-file-link.png"
            alt={storageprovider}
          />
        </div>
      )
    } else if (thumbUrl != null) {
      fileIcon = (
        <div className={styles.fileIcon}>
          <img src={thumbUrl} alt={txt} />
        </div>
      )
    } else if (thumbLoading === true) {
      fileIcon = (
        <div className={styles.fileIcon}>
          <Spin />
        </div>
      )
    } else {
      fileIcon = (
        <div className={styles.fileIcon}>
          <FTIcon colorType="multiColor" fileName={item.file_name} />
        </div>
      )
    }

    return (
      <List.Item key={item.file_digest}>
        <List.Item.Meta avatar={fileIcon} title={filenameElem} description={descElem} />
        <div className={classNames('btnContainer')}>
          {downloadBtn}
          {dropdownElem}
        </div>
      </List.Item>
    )
  }
}

export default FileItemDef
