import { getEnvParam } from 'env'
import { sendEncrypted } from 'pUtils'

// import { checkMember } from 'pages/chat/component/chatView/data/roomUtil'

import { clientForLanding } from 'myNet'
import Ngql from 'gql/ninegql'
import NQuery from './query'

export function getWorkspaceList() {
  const promise = new Promise((resolve, reject) => {
    const email = getEnvParam('userEmail')

    const params = {
      email,
    }

    sendEncrypted(
      '/payment/workspace/',
      params,
      (res) => {
        if (res.code === 200) {
          resolve(res)
        } else {
          reject(res)
        }
      },
      (ex) => {
        reject(ex)
      },
    )
  })

  return promise
}

export function getMyInfo() {
  const promise = new Promise((resolve, reject) => {
    Ngql.GQLObj(NQuery.queryMyAccount, {
      name: 'myInfo',
      useUI: false,
    })
      .query({}, clientForLanding)
      .then((res) => {
        console.log(' queryMyAccount - ', res)
        const { id, email, displayName, avatarUrl } = res.data.me
        const { serverType } = res.data;
        resolve({
          id,
          email,
          display_name: displayName,
          avatar_url: avatarUrl,
          serverType
        })
      })
      .catch((err) => {
        reject(err)
      })

    // const email = getEnvParam('userEmail')

    // checkMember([email], true)
    //   .then((res) => {
    //     console.log('checkMember - ', res)

    //     if (res.data.code === 200 && res.data.data.length > 0) {
    //       const myData = res.data.data[0]
    //       resolve(myData)
    //     } else {
    //       resolve({
    //         email,
    //       })
    //     }
    //   })
    //   .catch((err) => {
    //     reject(err)
    //   })
  })

  return promise
}

export function getWorkspaceInfo(workspaceId) {
  const promise = new Promise((resolve, reject) => {
    Ngql.GQLObj(NQuery.queryGetWorkspaceInfo, {
      name: 'workspaceInfo',
      useUI: false,
    })
      .query({ variables: { workspaceId } }, clientForLanding)
      .then((res) => {
        console.log(' getWorkspaceInfo - ', res)
        const workspaceInfo = res.data.getWorkspaceInfo
        resolve(workspaceInfo)
      })
      .catch((err) => {
        reject(err)
      })
  })

  return promise
}

const defaultData = {
  getWorkspaceList,
  getMyInfo,
  getWorkspaceInfo,
}

export default defaultData
