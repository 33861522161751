import React from 'react'
import { List, Spin, notification } from 'antd'

import 'react-perfect-scrollbar-z/build/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar-z'
import classNames from 'classnames'

import { getRoomInfo64 } from 'pages/chat/component/chatView/data/adminRoomUtil'
import dataMgr from 'pages/chat/component/chatView/data/dataManager'
import attachmentManager from 'pages/chat/component/chatView/data/attachmentManager'

import FileItem from './FileItem'
import ImageItem from './ImageItem'

import ChatUI from '../chatUI'
import styles from '../style.module.scss'

function getTotalPageCnt(total, pageSize) {
  let totalPage = Math.floor(total / pageSize)

  if (total > pageSize * totalPage) {
    totalPage += 1
  }

  return totalPage
}

class ItemListDef extends React.Component {
  state = {
    itemList: [],
    totalCount: 0,
    memberMap: {},
    loading: false,
    scrollTarget: null,
  }

  itemMap = {}

  itemList = []

  dataMgr = dataMgr

  scrollRef = React.createRef()

  hasNext = true

  isMessageFull = false

  pageNum = 0

  pageSize = 12

  prevParamData = {}

  itemHeight = -1

  componentDidMount() {
    const { pmId64, roomObj, mimeTypeRegEx, pageSize } = this.props

    this.fileUtil = new ChatUI(this)

    this.mimeTypeRegEx = mimeTypeRegEx

    if (pageSize != null) {
      this.pageSize = pageSize
    }

    if (pmId64 != null) {
      this.loadRoomInfo(pmId64)
    } else if (roomObj != null) {
      this.setRoomInfo(roomObj)
    }
  }

  componentDidUpdate(prevProps) {
    const { pmId64, roomObj, active } = this.props
    if (
      prevProps.pmId64 !== pmId64 ||
      prevProps.roomObj !== roomObj ||
      (prevProps.active !== active && active === true)
    ) {
      if (pmId64 != null) {
        this.loadRoomInfo(pmId64)
      } else if (roomObj != null) {
        this.setRoomInfo(roomObj)
      }
    }
  }

  componentWillUnmount() {
    if (this.memberListener != null) {
      this.dataMgr.removeRoomMemberListener(this.memberListener)
    }
  }

  loadRoomInfo = (pmId64) => {
    getRoomInfo64(pmId64).then((infoRes) => {
      const roomObj = infoRes.data.data
      this.setRoomInfo(roomObj)
    })
  }

  setRoomInfo = (roomObj) => {
    if (roomObj != null) {
      this.roomId = roomObj.ChatRoom.id

      if (this.memberListener != null) {
        this.dataMgr.removeRoomMemberListener(this.memberListener)
      }

      this.memberListener = {
        roomId: roomObj.ChatRoom.id,
        cbFunc: (roomId, memberList, state, self) => {
          console.log(' memberListener - ', roomId, memberList, state, self)

          this.setState({
            memberMap: this.dataMgr.globalMemberMap,
          })
        },
      }

      this.dataMgr.addRoomMemberListener(this.memberListener)

      this.dataMgr.getRoomMemberList(roomObj.ChatRoom.id)

      this.resetItemList()

      this.loadItem(null, null, null, this.checkOptions)
    }
  }

  checkOptions = (itemList, hasNext) => {
    const { options } = this.props

    console.log(' - options - ', options)
    console.log(' - itemList - ', itemList, hasNext)

    if (options != null) {
      const { objectId, type } = options

      if (objectId != null && type != null) {
        const existItem = itemList.find((item) => {
          if (item.originItem.object_id === objectId && item.originItem.type === type) {
            return true
          }
          return false
        })

        console.log(' - isExist - ', existItem)
        if (existItem != null) {
          this.setState({
            scrollTarget: existItem,
          })
        } else if (hasNext === true) {
          setTimeout(() => {
            this.moveNext(this.checkOptions)
          }, 100)
        }
      }
    }
  }

  getAttachment = (fileData) => {
    return attachmentManager.getAttachmentObjForFileView(fileData)
  }

  resetItemList = () => {
    this.isMessageFull = false
    this.hasNext = true
    this.itemList = []
    this.itemMap = {}
    this.prevParamData = {
      pageNum: 1,
    }
    this.setState({
      itemList: [],
      totalCount: 0,
    })
    if (this.scrollRef.current != null) {
      const elem = this.scrollRef.current.element
      elem.scrollTop = 0
    }
  }

  loadItem = (pageNum, sort, searchFileName, onLoaded) => {
    const { chatObj } = this.props

    const newSort = sort != null ? sort : 'create_time desc'

    this.pageNum = pageNum != null ? pageNum : 1

    this.prevParamData = {
      pageNum: this.pageNum,
      sort: newSort,
      searchFileName,
    }

    const filters = [`chat_room_id eq ${this.roomId}`, 'file_type eq file']

    if (searchFileName != null) {
      filters.push(`attachments.file_name like ${searchFileName}`)
    }

    if (this.mimeTypeRegEx != null) {
      filters.push(`attachments.mimetype re ${this.mimeTypeRegEx}`)
    }

    const params = {
      // page: this.pageNum,
      // per_page: this.pageSize,
      skip: (this.pageNum - 1) * this.pageSize,
      limit: this.pageSize,
      filters,
    }

    if (newSort != null) {
      params.sort = newSort
    }

    chatObj
      .getFileList(params)
      .then((res) => {
        console.log(' fileList - ', res)
        if (res.data.code === 200) {
          const { list, total_count: totalCount } = res.data.data

          const totalPage = getTotalPageCnt(totalCount, this.pageSize)

          if (this.pageNum > totalPage) {
            this.pageNum = totalPage
          }

          list.forEach((item) => {
            const attachment = this.getAttachment(item)
            attachment.originItem = item
            if (this.itemMap[item.file_digest] == null) {
              this.itemMap[item.file_digest] = attachment
              this.itemList.push(attachment)
            }
          })

          this.setState({
            itemList: [...this.itemList],
            totalCount,
            loading: false,
          })

          this.hasNext = this.pageNum < totalPage

          if (this.hasNext === false) {
            this.setState({
              loading: false,
            })

            if (onLoaded) {
              onLoaded(this.itemList, this.hasNext)
            }
          } else if (this.isMessageFull !== true) {
            // 화면이 꽉 찼는지 검사해서, 부족하면 더 로딩하도록 함.
            this.checkScreenFull(
              () => {
                this.moveNext()
              },
              () => {
                this.setState({
                  loading: false,
                })

                if (onLoaded) {
                  onLoaded(this.itemList, this.hasNext)
                }
              },
            )
          } else {
            this.setState({
              loading: false,
            })

            if (onLoaded) {
              onLoaded(this.itemList, this.hasNext)
            }
          }
        } else {
          this.setState({
            loading: false,
          })

          if (onLoaded) {
            onLoaded(null, false)
          }
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        })
        notification.error({
          message: err.code,
          description: err.message,
        })
      })
  }

  moveNext = (onLoaded) => {
    const { loading, totalCount } = this.state

    if (this.hasNext === true && loading !== true) {
      const { pageNum, sort, searchFileName } = this.prevParamData

      let pageNum1 = pageNum != null ? pageNum : 1
      const totalPage = getTotalPageCnt(totalCount, this.pageSize)

      if (pageNum1 < totalPage) {
        this.hasNext = true

        pageNum1 += 1

        this.setState({
          loading: true,
        })

        this.loadItem(pageNum1, sort, searchFileName, onLoaded)
      } else {
        this.hasNext = false
      }
    }
  }

  search = (txt) => {
    const { sort } = this.prevParamData

    // const pageNum1 = pageNum != null ? pageNum : 1

    console.log(' - onEnterSearchBox : ', txt)

    if (txt != null) {
      const val = txt

      this.resetItemList()
      if (val.length > 0) {
        this.loadItem(1, sort, val)
      } else {
        this.loadItem(1, sort)
      }
    }
  }

  refresh = () => {
    const { sort, searchFileName } = this.prevParamData

    this.resetItemList()

    this.setState({
      loading: true,
    })

    this.loadItem(1, sort, searchFileName)
  }

  applyHeight = () => {
    setTimeout(() => {
      if (this.bodyElem != null) {
        const scrollElem = this.bodyElem.querySelector('.scroll-content')
        if (scrollElem != null) {
          const itemsWrap = scrollElem.querySelector('.itemsWrap')
          this.bodyElem.style.height = `${itemsWrap.offsetHeight}px`
        }

        setTimeout(() => {
          if (this.scrollRef.current != null) {
            this.scrollRef.current.update()
          }
        }, 100)
      }
    }, 100)
  }

  checkScreenFull = (onNeedMoreFunc, onLoadedFullFunc) => {
    setTimeout(() => {
      console.log('scrollRef : ', this.scrollRef)
      if (this.scrollRef.current != null) {
        const elem = this.scrollRef.current.element

        // 기본 화면 높이보다 content가 적으면 다음 페이지 요청.
        if (this.helperElem != null && this.isMessageFull !== true) {
          let needMoreData = false
          const itemList = this.helperElem.querySelectorAll('.ant-row > div')

          if (itemList.length > 0) {
            const itemPerLine = Math.floor(this.helperElem.offsetWidth / itemList[0].offsetWidth)
            const lastFullLine = Math.floor(itemList.length / itemPerLine)
            let fullHeight = 0
            this.itemHeight = itemList[0].offsetHeight
            if (lastFullLine > 0) {
              const lastFullItem = itemList[itemPerLine * lastFullLine - 1]
              fullHeight = lastFullItem.offsetTop + lastFullItem.offsetHeight
            }
            needMoreData = fullHeight <= elem.offsetHeight
          } else {
            needMoreData = this.helperElem.offsetHeight <= elem.offsetHeight
          }

          if (needMoreData === true) {
            if (onNeedMoreFunc != null) {
              onNeedMoreFunc()
            }
          } else {
            this.isMessageFull = true
            if (onLoadedFullFunc != null) {
              onLoadedFullFunc()
            }
          }
        }
      }
    }, 100)
  }

  onScrollY = (e) => {
    console.log('onScrollY : ', e)
    console.log(' --- ', e.target.scrollHeight - e.target.scrollTop, e.target.offsetHeight)

    const bottomPosRange = 20

    const { scrollTop, scrollHeight, offsetHeight } = e.target

    if (scrollHeight - bottomPosRange < scrollTop + offsetHeight) {
      this.moveNext()
    }
    // else if (this.itemHeight > 0) {
    //   if (scrollHeight - this.itemHeight < scrollTop + offsetHeight) {
    //     this.moveNext();
    //   }
    // }

    // if (this.hasNext === true && scrollHeight - scrollTop === offsetHeight) {
    //   setTimeout(() => {
    //     if (e.target.scrollTop === scrollTop) {
    //       e.target.scrollTop = scrollTop - 1;
    //     }
    //   }, 200);
    // }
  }

  onYReachEnd = () => {
    // this.moveNext()
  }

  renderItem = (attachment) => {
    const { memberMap, scrollTarget } = this.state
    const { chatObj, type, itemMenuFunc } = this.props

    if (type === 'image') {
      let refFunc = null
      if (attachment === scrollTarget) {
        refFunc = (ref) => {
          console.log('ref --- ', ref)
          if (ref != null) {
            const elem = ref
            if (elem.scrollIntoViewIfNeeded != null) {
              elem.scrollIntoViewIfNeeded()
            } else {
              elem.scrollIntoView({ block: 'nearest' })
            }

            setTimeout(() => {
              this.setState({
                scrollTarget: null,
              })
            }, 10)
          }
        }
      }

      return (
        <ImageItem
          setRef={refFunc}
          memberMap={memberMap}
          chatObj={chatObj}
          attachment={attachment}
          fileUtil={this.fileUtil}
        />
      )
    }

    return (
      <FileItem
        memberMap={memberMap}
        chatObj={chatObj}
        attachment={attachment}
        fileUtil={this.fileUtil}
        itemMenuFunc={itemMenuFunc}
      />
    )
  }

  render() {
    const {
      itemList,
      loading,
      // totalCount
    } = this.state
    const { grid, type } = this.props

    // console.log(totalCount)

    return (
      <div
        ref={(ref) => {
          this.bodyElem = ref
        }}
        className={classNames('fileList', { imageType: type === 'image' })}
      >
        <PerfectScrollbar
          className={classNames(styles.filesWrap)}
          refScroll={this.scrollRef}
          onScrollY={this.onScrollY}
          onYReachEnd={this.onYReachEnd}
        >
          <div
            ref={(ref) => {
              this.helperElem = ref
            }}
            className={classNames('scrollHelper')}
          >
            <List
              grid={grid}
              className={styles.listContainer}
              dataSource={itemList}
              renderItem={this.renderItem}
            />
          </div>
        </PerfectScrollbar>
        <Spin className={styles.spin} tip="Loading more ..." spinning={loading} delay={300} />
      </div>
    )
  }
}

const ItemList = ItemListDef

export default ItemList
