import { resizeImg, getUniqueKey } from 'utils'

import { creactReactObjMgr } from 'pages/chat/component/chatView/data/dataUtil'
import imageDB from 'pages/chat/component/chatView/db/imageDB'

import { processImageBlob } from './cUtil'

// attachmentObj - chatview에서 사용하기 위한 object
// attachment - api에서 전달받은 attachment 정보.

const makeAttachmentObjProps = (attachmentObj, useDB) => {
  const self = attachmentObj

  self.isCalledLoadThumbFile = false

  self.useDB = useDB

  self.reactMgr = creactReactObjMgr()

  self.hasThumbImg = () => {
    const { mimetype, filetype } = self.info

    // @todo 나중에 반영 - 검토 후 todo 주석 제거. file_type
    if (filetype != null && filetype !== 'file') {
      return false
    }

    return mimetype != null && (mimetype.indexOf('image') === 0 || mimetype.indexOf('video') === 0)
  }

  self.getThumbDigest = (useOriginal) => {
    const { thumbFile, info } = self
    const { filedigest, filesize, mimetype } = info

    if (thumbFile == null && filedigest != null && self.hasThumbImg()) {
      const thumbLimit = 1 * 1024 * 1024

      // 이미지는 1mb 이상인 경우에만 thumb이미지 확인, 동영상은 무조건 확인.
      if (
        useOriginal !== true &&
        ((mimetype.indexOf('image') === 0 && filesize >= thumbLimit) ||
          mimetype.indexOf('video') === 0)
      ) {
        return `thumb/${filedigest}`
      }

      return filedigest
    }

    return null
  }

  self.deleteDB = () => {
    self.added = false
    return imageDB.deleteData(self.info.filedigest)
  }

  self.reloadData = (chatObj) => {
    const item = attachmentManager.getAttachmentObjByDigest(self.info.filedigest)
    if (item != null) {
      item.resetLoading()
      item.loadThumbFile(chatObj)
    }
  }

  self.resetLoading = () => {
    self.isCalledLoadThumbFile = false
    self.file = null
    self.dataURL = null
    self.thumbFile = null
  }

  self.clearThumbData = () => {
    self.isCalledLoadThumbFile = false
    self.dataURL = null
  }

  self.loadThumbFile = (chatObj) => {
    if (self.dataURL != null) {
      // 이미 로딩된 데이터를 가지고 있으면 그것을 사용한다.

      self.reactMgr.run((reactObj) => {
        reactObj.setState({
          thumbUrl: self.dataURL,
          thumbLoading: false,
        })
      })
    } else if (self.isCalledLoadThumbFile !== true && self.hasThumbImg() === true) {
      self.isCalledLoadThumbFile = true

      if (self.thumbFile != null) {
        self.readThumbImg(self.thumbFile)
      } else if (self.file != null) {
        self.readThumbImg(self.file, null)
      } else {
        const chatObj0 = chatObj != null ? chatObj : self.chatObj
        self.innerLoadThumbFile(chatObj0)
      }
    }
  }

  self.innerLoadThumbFile = (chatObj) => {
    const { filedigest } = self.info

    imageDB
      .getData(filedigest)
      .then((data) => {
        // console.log(' --- image data --- ', data)

        self.dataURL = data.url

        self.loadThumbFile(chatObj)
      })
      .catch((e) => {
        console.log(' --- thumb load ', e)
        setTimeout(() => {
          self.getImageFromApi(chatObj)
        }, 10)
      })
  }

  self.getImageFromApi = (chatObj, useOriginal) => {
    const { info } = self
    const { filename, mimetype } = info

    const thumbDigest = self.getThumbDigest(useOriginal)
    if (thumbDigest != null) {
      self.reactMgr.run((reactObj) => {
        reactObj.setState({
          thumbLoading: true,
        })
      })

      chatObj
        .xhrGetFile(thumbDigest)
        .then(({ xhr }) => {
          console.log('xhrGetFile', xhr)
          const blob = xhr.response

          processImageBlob(blob, filename, mimetype, (res) => {
            if (res == null) {
              self.reactMgr.run((reactObj) => {
                reactObj.setState({
                  thumbLoading: false,
                })
              })
            } else {
              const { blob: newBlob, thumbFile: newThumbFile } = res

              self.thumbFile = newThumbFile

              self.readThumbImg(newThumbFile, newBlob)
            }
          })
        })
        .catch((err) => {
          console.log('error - ', err)

          if (useOriginal !== true) {
            setTimeout(() => {
              self.getImageFromApi(chatObj, true)
            }, 10)
          } else {
            self.reactMgr.run((reactObj) => {
              reactObj.setState({
                thumbLoading: false,
              })
            })
          }
        })
    }
  }

  self.readThumbImg = (thumbFile, blob) => {
    self.reactMgr.run((reactObj) => {
      reactObj.setState({
        thumbLoading: true,
      })
    })

    const img = new Image()
    img.onload = () => {
      console.log(' img - onload : ', img.width, img.height)
      if (img.width < 800 && img.height < 800) {
        self.saveThumbFile(thumbFile, blob, img.src)
      } else {
        try {
          const largeV = Math.max(img.width, img.height)

          const maxV = 800

          const w = Math.round((img.width * maxV) / largeV)
          const h = Math.round((img.height * maxV) / largeV)

          resizeImg(img, w, h, (newBlob) => {
            // console.log('toBlob - ', newBlob)
            const newThumbFile = new File([newBlob], `${thumbFile.name}_thumb`)

            self.saveThumbFile(newThumbFile, newBlob, img.src)
          })
        } catch (err) {
          console.log(err)

          self.reactMgr.run((reactObj) => {
            reactObj.setState({
              thumbLoading: false,
            })
          })
        }
      }
    }
    img.onerror = () => {
      // 오류 발생 시 처리.
      window.URL.revokeObjectURL(img.src)

      self.reactMgr.run((reactObj) => {
        reactObj.setState({
          thumbLoading: false,
        })
      })
    }

    img.src = window.URL.createObjectURL(blob || thumbFile)
  }

  self.saveThumbFile = (thumbFile, blob, oldSrc) => {
    const { info } = self
    const { filedigest } = info

    const thumbBlob = new Blob([thumbFile], { type: thumbFile.type })
    self.dataURL = window.URL.createObjectURL(thumbBlob)
    self.loadThumbFile()

    // 입력창에 표시되는 thumbnail은 db에 저장하지 않도록 함. - 보내질지 안보내질지 모르는 상태임.
    if (self.added !== true && self.useDB !== false && blob != null) {
      self.added = true
      imageDB.putData(filedigest, blob).then((imgData) => {
        self.dataURL = imgData.url
        self.loadThumbFile()
      })
    }

    if (oldSrc != null) {
      window.URL.revokeObjectURL(oldSrc)
    }

    // const reader = new FileReader()

    // reader.onload = () => {
    //   const thumbUrl = reader.result

    //   self.reactMgr.run((reactObj) => {
    //     reactObj.setState({
    //       thumbUrl,
    //       thumbLoading: false,
    //     })
    //   })
    //   self.dataURL = thumbUrl
    // }

    // reader.onerror = () => {
    //   self.reactMgr.run((reactObj) => {
    //     reactObj.setState({
    //       thumbLoading: false,
    //     })
    //   })
    // }

    // reader.readAsDataURL(thumbFile)
  }
}

function AttachmentManager() {
  const self = this

  self.dataMap = {}
  self.dataList = []

  // message에 첨부되어 전송성공한 attachment를 관리.
  function createAttachmentObj(attachment) {
    const ret = {
      info: {
        origin: attachment,
        isNew: false,
        // @todo 나중에 반영 - 검토 후 todo 주석 제거. file_type
        filetype: attachment.file_type,
        fileurl: attachment.file_url,
        storageprovider: attachment.storage_provider,
        filename: attachment.file_name,
        filedigest: attachment.file_digest,
        filesize: attachment.file_size,
        mimetype: attachment.mimetype,
        dimension: attachment.dimension,
        verify: attachment.verify,
      },
      xhr: null,
      chatObj: null,
    }

    ret.getOrigin = () => {
      const origin = {
        // @todo 나중에 반영 - 검토 후 todo 주석 제거. file_type
        file_type: ret.info.filetype,
        file_name: ret.info.filename,
        file_digest: ret.info.filedigest,
        file_size: ret.info.filesize,
        mimetype: ret.info.mimetype,
        verify: ret.info.verify,
        dimension: ret.info.dimension,
      }

      if (ret.info.fileurl != null) {
        origin.file_url = ret.info.fileurl
      }
      if (ret.info.storageprovider != null) {
        origin.storage_provider = ret.info.storageprovider
      }

      return origin
    }

    makeAttachmentObjProps(ret, true)

    return ret
  }

  // message에 첨부되기 전, 파일 업로드를 관리.
  function createAttachmentObjForUpload(file) {
    const keyStr = getUniqueKey('tmpDigestKey')

    const ret = {
      file,
      info: {
        isNew: true,
        // @todo 나중에 반영 - 검토 후 todo 주석 제거. file_type
        filetype: 'file',
        filename: file.name,
        filedigest: `tmp_${keyStr}`,
        mimetype: file.type,
      },
    }

    ret.getOrigin = () => {
      const origin = {
        // @todo 나중에 반영 - 검토 후 todo 주석 제거. file_type
        file_type: ret.info.filetype,
        file_name: ret.info.filename,
        file_digest: ret.info.filedigest,
        file_size: ret.info.filesize,
        mimetype: ret.info.mimetype,
        verify: ret.info.verify,
        dimension: ret.info.dimension,
        temp_digest: ret.info.tempdigest,
      }

      if (ret.info.fileurl != null) {
        origin.file_url = ret.info.fileurl
      }
      if (ret.info.storageprovider != null) {
        origin.storage_provider = ret.info.storageprovider
      }

      return origin
    }

    ret.onProgress = (evt, percent) => {
      console.log('onProgress - ', evt, percent)
      ret.reactMgr.run((reactObj) => {
        if (reactObj.onProgress != null) {
          reactObj.onProgress(evt, percent)
        }
      })
    }

    ret.setUploadInfo = (xhr, chatObj) => {
      ret.xhr = xhr
      ret.chatObj = chatObj
    }

    ret.onUploaded = (filename, filedigest, mimetype, filesize, verify, dimension, tempdigest) => {
      ret.info = {
        ...ret.info,
        filename,
        filedigest,
        mimetype,
        filesize,
        verify,
      }

      if (dimension != null) {
        ret.info.dimension = dimension
      }

      if (tempdigest != null) {
        ret.info.tempdigest = tempdigest
      }

      // 업로드한 이미지를 저장.
      if (ret.hasThumbImg() === true) {
        const thumbFile = ret.thumbFile != null ? ret.thumbFile : ret.file

        const thumbBlob = new Blob([thumbFile], { type: thumbFile.type })
        imageDB.putData(filedigest, thumbBlob).then((imgData) => {
          const attachmentObj = self.getAttachmentObjByDigest(imgData.digest)
          if (attachmentObj != null) {
            attachmentObj.dataURL = imgData.url
            attachmentObj.loadThumbFile()
          }
        })
      }
    }

    ret.deleteFile = (isNew) => {
      if (ret.xhr != null) {
        try {
          ret.xhr.abort()
        } catch (err) {
          console.log(err)
        }
        ret.xhr = null
      }

      if (ret.info != null && ret.info.filedigest != null) {
        if (isNew === false || ret.info.isReplace === true) {
          // 이미 존재하는 메시지에서 첨부를 지우면, update message만 처리하면 됨.
          // 파일을 따로 안지워도 서버에서 처리함.
          // ...
          if (ret.info.tempdigest != null) {
            const thumbDigest = `thumb/${ret.info.tempdigest}`
            const tmpArr = [thumbDigest, ret.info.tempdigest]
            ret.chatObj.deleteTempFile(tmpArr)
          }
        }
        // else {
        //   const thumbDigest = ret.getThumbDigest()
        //   if (thumbDigest != null) {
        //     ret.chatObj.deleteFile(thumbDigest)
        //   }
        //   return ret.chatObj.deleteFile(ret.info.filedigest)
        // }
      }
      return null
    }

    makeAttachmentObjProps(ret, false)

    return ret
  }

  self.getAttachmentObj = (attachment) => {
    const digest = attachment.file_digest
    if (self.dataMap[digest] == null) {
      const obj = createAttachmentObj(attachment)
      self.dataMap[digest] = obj
      self.dataList.push(obj)
    }

    return self.dataMap[digest]
  }

  self.getAttachmentForUpload = (file) => {
    return createAttachmentObjForUpload(file)
  }

  self.getAttachmentObjByDigest = (digest) => {
    return self.dataMap[digest]
  }

  self.getAttachmentObjForFileView = (fileData) => {
    // fileList의 데이터는 attachment와 동일.
    if (
      self.dataMap[fileData.file_digest] == null ||
      self.dataMap[fileData.file_digest].info.origin.update_time == null
    ) {
      self.dataMap[fileData.file_digest] = createAttachmentObj(fileData)
    }

    return self.dataMap[fileData.file_digest]
  }
}

const attachmentManager = new AttachmentManager()

export default attachmentManager
