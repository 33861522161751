import React from 'react'
import { notification, Modal } from 'antd'
import store from 'store'
import i18n from 'i18n'
// import moment from 'moment'
// import { ClientJS } from 'clientjs'
// import { ApolloError } from '@apollo/client'

import { store as reduxStore } from 'index'
import { setEnvParam, getEnvParam, removeEnvParam } from 'env'

import { getInitialAvatar } from 'uiUtil'

import mailApi from 'services/mailApi'
// import WorkspaceSelector from 'components/WorkspaceSelector'

import {
  clientForRefreshToken,
  // clientForLanding,
  restLandingClient
} from 'myNet'
import { sendEncrypted, getDeviceUUID, updateAccountInfo } from 'pUtils'

import dataMgr from 'pages/chat/component/chatView/data/dataManager'
// import { startWS, stopWS } from 'services/websocket'
// import { getMessagingToken, deleteMessagingToken } from 'services/firebase'

// import imageDB from 'pages/chat/component/chatView/db/imageDB'
// import messageDB from 'pages/chat/component/chatView/db/messageDB'
// import mainDB from 'pages/chat/component/chatView/db/mailDB'
// import linkPreviewDB from 'pages/chat/component/chatView/db/linkPreviewDB'

// eslint-disable-next-line import/no-cycle
import Ngql from 'gql/ninegql'
// import { parseJwt, processError } from 'utils'
// import { processError } from 'utils'

import {
  getMyInfo,
  getWorkspaceInfo,
  // getWorkspaceList
} from './api'

// import NQuery from './query'

const mutationUpdataToken = Ngql.Mutation('refreshToken', {
  refreshToken: Ngql.Node(
    {
      token: true,
      refreshExpiresIn: true,
      refreshToken: true,
      // payload: true
    },
    null,
    false,
    {
      // refreshToken: Ngql.Var('refreshToken', 'String!'),
    },
  ),
})

const updateInfo = {
  isUpdating: false,
  currentQuery: null,
  waitingReq: [],
}

export function updateToken() {
  const refreshToken = store.get('app.user.refreshToken')
  let ret = null
  if (refreshToken != null) {
    if (updateInfo.isUpdating === false) {
      updateInfo.isUpdating = true
      // store.set('app.user.token', refreshToken)
      updateInfo.currentQuery = Ngql.GQLObj(mutationUpdataToken, {
        // vars: {
        //   refreshToken,
        // },
      })
        .mutate({}, clientForRefreshToken)
        .then((res) => {
          if (
            res.data.refreshToken.token == null ||
            res.data.refreshToken.token.trim().length === 0
          ) {
            throw new Error('updateToken - empty token..!')
          }
          store.set('app.user.token', res.data.refreshToken.token)
          store.set('app.user.refreshExpiresIn', res.data.refreshToken.refreshExpiresIn)
          store.set('app.user.refreshToken', res.data.refreshToken.refreshToken)

          updateInfo.waitingReq.forEach((promise) => {
            promise.resolve(res.data.refreshToken.token)
          })
          updateInfo.waitingReq = []

          updateInfo.isUpdating = false
          updateInfo.currentQuery = null
          return res.data.refreshToken.token
        })
        .catch(() => {
          store.remove('app.user.token')

          updateInfo.waitingReq.forEach((promise) => {
            promise.reject()
          })
          updateInfo.waitingReq = []

          updateInfo.isUpdating = false
          updateInfo.currentQuery = null

          reduxStore.dispatch({
            type: 'user/LOGOUT',
          })
        })

      ret = updateInfo.currentQuery
    } else {
      const retPromise = new Promise((resolve, reject) => {
        updateInfo.waitingReq.push({
          resolve,
          reject,
        })
      })
      ret = retPromise
    }
  }
  return ret
}

// export function customFetch(uri, options) {
//   console.log(' ==== customFetch ', uri, options, this)
//   // This reference to the refreshingPromise will let us check later on if we are executing getting the refresh token.
//   // Create initial fetch, this is what would normally be executed in the link without the override
//   const initialRequest = fetch(uri, options)

//   let responseData = null
//   // The apolloHttpLink expects that whatever fetch function is used, it returns a promise.
//   // Here we return the initialRequest promise
//   return initialRequest
//     .then((response) => {
//       const ret = response.clone().json()
//       console.log(' ==== fetch result ', response, ret)
//       responseData = response
//       return ret
//     })
//     .then((error) => {
//       if (error && error.code !== 200) {
//         let retPromise = null
//         let ret = false
//         processError(error, (code, message) => {
//           if (code === 402 || message.indexOf('Signature has expired') > -1) {
//             console.log(' !!! Signature has expired ')
//             const updateHandle = updateToken()
//             if (updateHandle != null) {
//               retPromise = new Promise((resolve, reject) => {
//                 updateHandle
//                   .then((token) => {
//                     console.log(' new token : ', token)
//                     if (token != null) {
//                       options.headers.authorization = `JWT ${token}`
//                       window.fetch(uri, options).then((res2) => {
//                         resolve(res2)
//                       })
//                     }
//                   })
//                   .catch(() => {
//                     reject()
//                   })
//               })
//               ret = true
//             } else {
//               notification.warning({
//                 message: code,
//                 description: message,
//               })
//             }
//             // } else if (message.indexOf('Unauthenticated token') > -1) {
//             //   if (window.parent === window) {
//             //     setLoginVisible(true)
//             //   } else {
//             //     window.parent.nPopup.setLoginVisible(true)
//             //   }
//             //   return true
//             // }
//           }
//           return ret
//         })

//         if (retPromise != null) {
//           return retPromise
//         }

//         // if (isError === true) {
//         //   const err = new ApolloError();
//         //   err.code = error.code;
//         //   err.errors = error.errors;
//         //   throw err;
//         // }
//       }

//       return responseData
//     })
// }
const deviceModel = 'x86_64'

const landingInfo = {
  ms365: {
    provider: 'msal',
    type: 'graph',
  },
  google: {
    provider: 'gmail',
    schema: 'workin-web',
  },
  imap: {
    provider: 'imap',
    type: null,
  },
}

function getProviderLandingInfo() {
  const autoDiscoverRes = getEnvParam('providerInfo')
  if (autoDiscoverRes != null) {
    const type = autoDiscoverRes.type.trim().toLowerCase()

    switch (type) {
      case 'ms365':
        return landingInfo.ms365
      // case 'gmail'
      case 'google':
        return landingInfo.google
      case 'imap':
        return landingInfo.imap
      default:
    }
  }

  return null
}

const registerInfo = {
  ms365: {
    ewsURI: 'https://outlook.office365.com/EWS/Exchange.asmx',
    authType: 'oauth',
    provider: 'ms365',
    protocol: 'graph', // graph or ews
  },
  google: {
    ewsURI: '',
    authType: 'oauth',
    provider: 'gmail',
    protocol: 'http',
  },
  imap: {
    // ewsURI: incomingServer.hostname,
    // port: incomingServer.post
    authType: 'basicauth',
    provider: 'imap',
    protocol: 'imap',
  },
}

function getProviderRegisterInfo() {
  const autoDiscoverRes = getEnvParam('providerInfo')
  if (autoDiscoverRes != null) {
    const type = autoDiscoverRes.type.trim().toLowerCase()

    switch (type) {
      case 'ms365':
        return registerInfo.ms365
      // case 'gmail'
      case 'google':
        return registerInfo.google
      case 'imap':
        return {
          ...registerInfo.imap,
          ewsURI: autoDiscoverRes.incomingServer.hostname,
          port: autoDiscoverRes.incomingServer.port,
        }
      default:
    }
  }

  return null
}

export function autoDiscover(email, saveEnv) {
  const promise = new Promise((resolve, reject) => {
    restLandingClient
      .get('/autodiscover/autoconfig', {
        email,
      })
      .then((res) => {
        if (res.data.code === 200) {
          if (saveEnv === true) {
            setEnvParam('providerInfo', res.data.data)
          }
          resolve(res)
        } else {
          setEnvParam('providerInfo', null)
          reject(new Error('error'))
        }
      })
      .catch((err) => {
        setEnvParam('providerInfo', null)
        reject(err)
      })
  })

  return promise
}

function selectWorkspace(email, onOk, onError) {
  // const selectInfo = {
  //   workspace: null,
  // }

  // const onSetWorkspace = (workspaceId, workspace) => {
  //   console.log(workspaceId, workspace)
  //   selectInfo.workspace = workspace
  // }

  // Modal.info({
  //   title: 'Select Workspace',
  //   content: <WorkspaceSelector type="owner" email={email} onSetWorkspace={onSetWorkspace} />,
  //   okText: 'Ok',
  //   onOk: () => {
  //     if (onOk != null) {
  //       onOk(selectInfo.workspace)
  //     }
  //   },
  // })

  restLandingClient
    .post('/provisioning/appconfig', {
      email,
    })
    .then((res) => {
      console.log(' res - ', res)
      const { data } = res
      if (data.code === 200) {
        const appconfigs = data.data.appconfig
        if (appconfigs.length > 0) {
          onOk(appconfigs[0])
        }
      } else if (data.message != null) {
        notification.warning({
          message: data.code,
          description: data.message,
        })
        onError(new Error(data.message))
      }
    })
    .catch((err) => {
      console.log(' err - ', err)
      onError(err)
    })
}

// function updateDevice(email0, prevFcmToken) {
//   const promise = new Promise((resolve) => {
//     const innerUpdateDevice = (messagingToken, email) => {
//       const deviceUUID = getDeviceUUID(email)
//       const workspaceId = store.get('app.user.workspace_id')

//       const client = new ClientJS()

//       let os = ''
//       if (client.isMac()) {
//         os = 'mac'
//       } else if (client.isWindows()) {
//         os = 'windows'
//       } else {
//         os = 'linux'
//       }

//       const appVersion = process.env.REACT_APP_VERSION

//       Ngql.GQLObj(NQuery.mutationUpdateDevice, {
//         vars: {
//           deviceUuid: deviceUUID,
//           workspaceId,
//           deviceData: {
//             deviceUuid: deviceUUID,
//             fcmToken: messagingToken,
//             type: 'web',
//             name: deviceModel,
//             os,
//             osVersion: client.getOSVersion(),
//             appVersion,
//           },
//         },
//       })
//         .mutate({}, clientForLanding)
//         .then((res1) => {
//           console.log(' updateDevice - ', res1)
//         })
//         .catch((err) => {
//           console.log(err)
//         })
//         .finally(() => {
//           resolve()
//         })
//     }

//     getMessagingToken()
//       .then((messagingToken) => {
//         if (prevFcmToken == null || messagingToken !== prevFcmToken) {
//           console.log('firebase token - ', messagingToken)

//           // if (process.env.REACT_APP_TARGET !== 'production') {
//           store.set('app.user.fcmToken', messagingToken)
//           store.set('app.user.fcmToken_date', moment().valueOf())
//           // }

//           innerUpdateDevice(messagingToken, email0)
//         } else {
//           resolve()
//         }
//       })
//       .catch((err) => {
//         console.log(err)
//         innerUpdateDevice(null, email0)
//       })
//   })

//   return promise
// }

// function authRegister(username, email) {
function authRegister(email) {
  const promise = new Promise((resolve, reject) => {
    const deviceUUID = getDeviceUUID(email)

    selectWorkspace(
      email,
      (appconfig) => {
        let info = getProviderRegisterInfo()
        if (info != null) {
          store.set('app.user.registorInfo', JSON.stringify(info))
        } else {
          const str = store.get('app.user.registorInfo')
          if (str != null) {
            info = JSON.parse(str)
          } else {
            reduxStore.dispatch({
              type: 'user/LOGOUT',
            })
            reject(new Error('no registor info.'))
          }
        }
        const params = {
          username: email, // display_name 아님. email을 사용해야 함.
          email,
          password: '',
          deviceModel,
          deviceUUID,
          appCategory: 'enterprise', // store
          workspaceId: appconfig.workspace_id,
          // ewsURI: 'https://outlook.office365.com/EWS/Exchange.asmx',
          // authType: 'oauth',
          // provider: 'ms365',
          // protocol: 'graph', // graph or ews
          ...info,
        }
        sendEncrypted(
          '/auth/register/web/',
          params,
          (res) => {
            if (res.code === 200) {
              console.log('success ~~!!! ', res)

              store.set('app.user.token', res.data.pAccessToken)
              store.set('app.user.connection_digest', res.data.connection_digest)
              store.set('app.user.refreshToken', res.data.pRefreshToken)
              store.set('app.user.workspace_id', res.data.workspace_id)
              store.set('app.user.deviceUUID', deviceUUID)

              // const workspace = {
              //   workspace_id: appconfig.workspace_id,
              //   icon_url: appconfig.brandingLogo,
              //   workspace_name: appconfig.appServicePublisher,
              //   product_name: appconfig.brandingName,
              // }

              // store.set('app.user.workspace', JSON.stringify(workspace))

              // startWS()

              // firebase 등록 처리.
              // updateDevice(email).finally(() => {
              //   resolve(res)
              // })

              resolve(res)
            } else {
              reject(res)
            }
          },
          (ex) => {
            console.log(ex)
            notification.warning({
              message: ex.code,
              description: ex.message,
            })
            reject(ex)
          },
        )
      },
      (err) => {
        reject(err)
      },
    )
  })

  return promise
}

async function landinglogin(email) {
  const promise = new Promise((resolve, reject) => {
    const onSuccessLanding = (e) => {
      console.log(' -- message : ', e)

      if (window.location.origin === e.origin || e.origin.indexOf('rework.so') < 0) {
        return
      }

      const data = JSON.parse(e.data)

      if (data.params.result === 'OK') {
        console.log(' -- params : ', data.params)

        store.set('app.user.providerToken', data.params.accessToken)

        // store.set('app.user.email', email)

        setEnvParam('userEmail', email)

        // authRegister(data.params.username, email)
        authRegister(email)
          .then((res) => {
            window.removeEventListener('message', onSuccessLanding)
            resolve(res)
          })
          .catch((err) => {
            window.removeEventListener('message', onSuccessLanding)
            reject(err)
          })
      }

      if (window.loginWin != null) {
        window.loginWin.close()
        window.loginWin = null
      }
    }

    window.addEventListener('message', onSuccessLanding, false)

    const info = getProviderLandingInfo()

    const params = {
      email,
    }

    if (info.type != null) {
      params.type = info.type
    }

    if (info.schema != null) {
      params.schema = info.schema
    }

    restLandingClient
      .get(`/auth/landing/${info.provider}`, params)
      .then((res) => {
        console.log('data - ', res.data)

        if (res.data.code === 200) {
          const url = res.data.data.auth_url

          const windowSize = {
            width: 500,
            height: 600,
          }
          const windowLocation = {
            left: window.screen.availLeft + window.screen.availWidth / 2 - windowSize.width / 2,
            top: window.screen.availTop + window.screen.availHeight / 2 - windowSize.height / 2,
          }

          const options = `width=${windowSize.width},height=${windowSize.height},left=${windowLocation.left},top=${windowLocation.top}`

          window.loginWin = window.open(null, 'landingWin', options)

          if (window.loginWin == null) {
            Modal.warning({
              title: i18n.t('common.title.blockPopup'),
              content: i18n.t('common.warning.blockPopup'),
              maskTransitionName: 'maskTransitionName',
            })

            reduxStore.dispatch({
              type: 'user/LOGOUT',
            })

            return
          }

          window.loginWin.location.href = url;
        }
      })
      .catch((error) => {
        console.log('error - ', error)
        notification.warning({
          message: error.code,
          description: error.message,
        })
      })
  })

  return promise
}

async function loginIMAP(email, password) {
  const promise = new Promise((resolve, reject) => {
    const autoDiscoverRes = getEnvParam('providerInfo')

    const params = {
      host: autoDiscoverRes.incomingServer.hostname,
      port: autoDiscoverRes.incomingServer.port,
      email,
      password,
    }

    restLandingClient
      .post(`/auth/verifying/imap`, params)
      .then((res) => {
        console.log('data - ', res.data)

        if (res.data.code === 200) {
          setEnvParam('userEmail', email)

          // authRegister(email, email)
          authRegister(email)
            .then((res2) => {
              resolve(res2)
            })
            .catch((err) => {
              reject(err)
            })
        } else if (res.data.code === 500) {
          notification.warning({
            message: res.data.code,
            description: res.data.message,
          })
        } else {
          reject(new Error('login failed'))
        }
      })
      .catch((error) => {
        console.log('error - ', error)
        notification.warning({
          message: error.code,
          description: error.message,
        })
        reject(error)
      })
  })

  return promise
}

export async function login(email, password) {
  const autoDiscoverRes = getEnvParam('providerInfo')
  if (autoDiscoverRes != null) {
    const type = autoDiscoverRes.type.trim().toLowerCase()

    switch (type) {
      case 'ms365':
      case 'google':
        return landinglogin(email)
      case 'imap':
        return loginIMAP(email, password)
      default:
    }
  }

  return null
}

export async function register(/* email, password, name */) {
  function fakeRegister() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 200)
    })
  }

  return fakeRegister()
}

// async function loadingCheck() {
//   let checkCnt = 0
//   const promise = new Promise((resolve, reject) => {
//     const checkDB = () => {
//       if (checkCnt > 50) {
//         // 로딩에 5초이상 소요되면 reject.
//         reject(new Error('DB loading fail'))
//       }
//       if (
//         imageDB.isActive === true &&
//         messageDB.isActive === true &&
//         mainDB.isActive === true &&
//         linkPreviewDB.isActive === true
//       ) {
//         console.log(' db loading count : ', checkCnt)
//         resolve(true)
//       } else {
//         checkCnt += 1
//         setTimeout(checkDB, 100)
//       }
//     }

//     checkDB()
//   })

//   return promise
// }

export async function currentAccount() {
  const workspaceId = store.get('app.user.workspace_id')
  // const deviceUUID = store.get('app.user.deviceUUID');

  if (workspaceId == null) {
    reduxStore.dispatch({
      type: 'user/LOGOUT',
    })
    return null
  }
  // const str = store.get('app.user.chatInfo')

  // const myInfo = str != null ? JSON.parse(str) : {}

  // console.log(myInfo)

  // const client = new ClientJS();

  // console.log('device - ', client.getDevice());
  // console.log('deviceType - ', client.getDeviceType());
  // console.log('deviceVendor - ', client.getDeviceVendor());

  // console.log('cpu - ', client.getCPU());
  // console.log('os - ', client.getOS());
  // console.log('osVersion - ', client.getOSVersion());
  // console.log('getBrowser - ', client.getBrowser());
  // console.log('getBrowserVersion - ', client.getBrowserVersion());

  let user = null
  const token = store.get('app.user.providerToken')
  if (token && token.length > 0) {
    const mailApiInstance = mailApi.init()

    if (mailApiInstance == null) {
      // provider 가 없는 상태. 오류임.
      reduxStore.dispatch({
        type: 'user/LOGOUT',
      })

      return null
    }

    user = await mailApi.getCurrentAccount()
  }

  if (user != null) {
    // selectWorkspace(user.mail, () => {
    //   console.log('test');
    // })

    const email = user.mail || user.userPrincipalName || ''

    // login한 직후에만 존재하는 정보임. 로그인에 사용한 이메일 주소와 office에서 받은 이메일이 같은지 확인이 필요.
    const loginEmail = getEnvParam('userEmail')

    // const loginEmail = store.get('app.user.email')

    setEnvParam('userEmail', email)

    if (loginEmail != null && loginEmail !== email) {
      console.log(' email is different from login email.', loginEmail, email)
      setEnvParam('userEmail', loginEmail)

      const errorTxt = i18n.t('login.error.emailInfoInvalid')
      const config = {
        title: 'Error',
        content: <div dangerouslySetInnerHTML={{ __html: errorTxt }} />,
        // afterClose: () => {
        //   reduxStore.dispatch({
        //     type: 'user/LOGOUT',
        //   })
        // }
      }

      Modal.error(config)

      reduxStore.dispatch({
        type: 'user/LOGOUT',
      })
      return null
    }
  }

  const myInfo = await getMyInfo()

  let displayName = myInfo.display_name

  // displayName이 정의되지 않은 경우. 기본값을 설정한다.
  if (myInfo.display_name == null || myInfo.display_name.trim().length === 0) {
    if (user != null && user.displayName != null && user.displayName.length > 0) {
      displayName = user.displayName
    } else {
      const pos = myInfo.email.indexOf('@')
      displayName = myInfo.email.substring(0, pos)
    }

    await updateAccountInfo(myInfo.id, displayName, null, true)
  }

  setEnvParam('userEmail', myInfo.email)

  // startWS()

  // firebase 처리.
  /* @todo - 2달(?) 이상 넘어가면 토큰이 만료됨. 이에 대한 처리는? 일단 놔두자. 로그인할 때 다시 발급함. */
  // const fcmTokenTime = store.get('app.user.fcmToken_date')

  // if (fcmTokenTime == null || fcmTokenTime.length === 0) {
  //   getMessagingToken()
  // } else {
  //   const curTime = moment()
  //   const prevTime = moment(fcmTokenTime)
  //   const diffDay = curTime.diff(prevTime, 'days', true)

  //   // const username = myInfo.display_name != null ? myInfo.display_name : myInfo.email
  //   if (diffDay > 30) {
  //     updateDevice(myInfo.email)
  //   } else {
  //     const prevFcmToken = store.get('app.user.fcmToken')
  //     updateDevice(myInfo.email, prevFcmToken)
  //   }
  // }

  // const res = await getWorkspaceList()

  // console.log('myInfo - ', myInfo, res.data.workspace_list)

  // const workspace = res.data.workspace_list.find((item) => {
  //   if (item.workspace_id === workspaceId) {
  //     return true
  //   }
  //   return false
  // })

  // const workspaceStr = store.get('app.user.workspace')

  // const workspace =
  //   workspaceStr != null ? JSON.parse(workspaceStr) : { workspace_id: workspaceId }
  let workspaceInfo = null

  try {
    workspaceInfo = await getWorkspaceInfo(workspaceId)
  } catch (e) {
    console.log(' - getWorkspaceInfo error : ', e)
    notification.error({
      message: e.code,
      description: e.message,
    })
    reduxStore.dispatch({
      type: 'user/LOGOUT',
    })
    return null
  }

  console.log(' workspaceInfo - ', workspaceInfo)

  const workspace = {
    id: workspaceId,
    workspace_name: workspaceInfo.workspaceName,
    workspace_email: workspaceInfo.workspaceEmail,
    icon_url: workspaceInfo.iconUrl,
    role: workspaceInfo.role,
  }

  if (workspace.role !== 'admin' && workspace.role !== 'owner') {
    notification.error({
      message: i18n.t('common.title.permission'),
      description: i18n.t('common.warning.permission'),
    })
    reduxStore.dispatch({
      type: 'user/LOGOUT',
    })

    return null;
  }

  const appTypeInfo = {
    enableChat: workspaceInfo.enableChat,
    role: workspaceInfo.role
  };

  setEnvParam('appType', JSON.stringify(appTypeInfo));

  if (workspace.icon_url == null) {
    if (workspace.workspace_name != null) {
      workspace.iconElem = await getInitialAvatar(workspace.workspace_name, workspace.workspace_email)
    }
  }

  if (myInfo.avatar_url == null) {
    const officeAvatarBlob = await mailApi.getMyProfilePhoto()
    // console.log(' office avatar - ', officeAvatar);
    if (officeAvatarBlob != null) {
      myInfo.avatar_url = window.URL.createObjectURL(officeAvatarBlob)
    }
  }

  const avatarUrl = myInfo.avatar_url != null ? myInfo.avatar_url : ''

  // await loadingCheck()

  return {
    id: myInfo.id,
    name: displayName || '',
    email: myInfo.email,
    // timeFormat: user.mailboxSettings?.timeFormat || '',
    // timeZone: user.mailboxSettings?.timeZone || 'UTC'
    uid: '',
    avatar: avatarUrl,
    role: workspaceInfo.role,
    lastLogin: '',
    workspace,
  }

  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve(false)
  //   }, 200)
  // })
}

export async function logout() {
  // return firebaseAuth()
  //   .signOut()
  //   .then(() => true)

  function fakeLogout() {
    store.remove('app.user.providerToken')
    store.remove('app.user.connection_digest')
    store.remove('app.user.token')
    store.remove('app.user.chatInfo')
    store.remove('app.user.refreshToken')
    store.remove('app.user.workspace_id')
    store.remove('app.user.deviceUUID')
    store.remove('app.user.fcmToken')
    store.remove('app.user.fcmToken_date')
    store.remove('app.user.registorInfo')

    store.remove('app.user.email')

    // 사용하지 않는 key 이지만, 이전의 데이터를 지우기 위해 남겨놓음.
    store.remove('app.user.workspace')

    removeEnvParam('appType');

    // deleteMessagingToken()

    dataMgr.clear()

    // stopWS()

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 200)
    })
  }

  return fakeLogout()
}

const apis = {
  // customFetch,
  login,
  logout,
}

export default apis
