import React from 'react'
import { Tag, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import PerfectScrollbar from 'react-perfect-scrollbar-z'
import parse from 'html-react-parser'

import classNames from 'classnames'
import i18n from 'i18n'

import { openAddMember } from 'pages/chat/component/chatUI'
import { searchMember } from 'pages/chat/component/chatView/data/adminRoomUtil'
import { getMemberElem } from 'pages/chat/component/MemberView/memberUtil'

import dataManager from '../chatView/data/dataManager'

import styles from '../style.module.scss'

class SelectMemberDef extends React.Component {
  state = {
    fixedList: [],
    addList: [],
  }

  searchTimer = null

  tempMemberMap = {}

  componentDidMount() {
    const { fixedList, defaultList } = this.props

    const newState = {}
    if (defaultList != null) {
      newState.addList = [...defaultList]
    }
    if (fixedList != null) {
      newState.fixedList = [...fixedList]
    }
    if (defaultList != null || fixedList != null) {
      this.setState(newState)
    }
  }

  componentWillUnmount() {}

  onAddList = (emailList) => {
    const { onListChange } = this.props
    const { fixedList, addList } = this.state

    console.log('onAddList - ', emailList)

    if (emailList == null || emailList.length === 0) {
      return
    }

    const curList = [...fixedList, ...addList]

    emailList.forEach((email) => {
      if (curList.indexOf(email) < 0) {
        addList.push(email)
      }
    })

    if (onListChange != null) {
      onListChange([...fixedList, ...addList].sort())
    }

    this.setState({
      addList: [...addList],
    })
  }

  innerSearchMember = (str, onGetList) => {
    const { memberList } = this.props
    const { fixedList, addList } = this.state

    const txt = str || ''

    if (txt.length === 0) {
      if (onGetList != null) {
        onGetList(dataManager.globalMemberList)
      }

      return
    }

    searchMember(txt)
      .then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          if (res.data.data != null) {
            let members = [...res.data.data]
            const curList = [...fixedList, ...addList]

            members = members.filter((member) => {
              return curList.indexOf(member.email) < 0
            })

            if (memberList != null) {
              memberList.forEach((item) => {
                members.some((member, idx) => {
                  if (member.email === item.email) {
                    members.splice(idx, 1)
                    return true
                  }
                  return false
                })
              })
            }

            if (onGetList != null) {
              onGetList(members)
            }
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  removeTag = (tag) => {
    const { onListChange } = this.props
    const { fixedList, addList } = this.state

    const pos = addList.indexOf(tag)
    if (pos >= 0) {
      addList.splice(pos, 1)

      if (onListChange != null) {
        onListChange([...fixedList, ...addList].sort())
      }

      this.setState({
        addList: [...addList],
      })
    }
  }

  showAddMember = (exceptedList) => {
    const opts = {
      title: i18n.t('chat.title.addMember'),
      onOk: (retObj, closeFunc) => {
        const textElem = parse(
          i18n.t('chat.setting.confirmAddMember', { count: retObj.addList.length }),
        )
        Modal.confirm({
          title: i18n.t('chat.title.addMember'),
          wrapClassName: classNames(styles.popupBase),
          icon: null,
          content: <span>{textElem}</span>,
          okText: i18n.t('chat.button.add'),
          okButtonProps: {
            type: 'text',
          },
          cancelButtonProps: {
            type: 'text',
          },
          onOk: () => {
            this.onAddList(retObj.addList)

            closeFunc()
          },
        })
      },
      fixedList: [],
      addList: [],
      exceptedList,
      enableDelete: true,
      onListChange: (list, tmpMemberList) => {
        tmpMemberList.forEach((member) => {
          this.tempMemberMap[member.email] = member
        })
      },
    }

    openAddMember(opts)
  }

  onTagKeyDown = (e) => {
    console.log('onTagKeyDown - ', e)
  }

  render = () => {
    const { roomObj, enableDelete } = this.props
    const { fixedList, addList } = this.state

    const fixedOwnerTagsElem = []
    const fixedTagsElem = []

    const ownerTagsElem = []
    const tagsElem = []

    const memberMap =
      roomObj != null && roomObj.roomMemberMap != null
        ? roomObj.roomMemberMap
        : dataManager.globalMemberMap

    fixedList.forEach((tag) => {
      const member = memberMap[tag] || this.tempMemberMap[tag]
      const memberElem = getMemberElem(tag, member, styles)
      const elem = (
        <Tag className="edit-tag member fixed" key={tag}>
          {memberElem}
        </Tag>
      )
      if (roomObj != null && member != null && member.type === 'owner') {
        fixedOwnerTagsElem.push(elem)
      } else {
        fixedTagsElem.push(elem)
      }
    })

    addList.forEach((tag) => {
      const member = memberMap[tag] || this.tempMemberMap[tag]
      const memberElem = getMemberElem(tag, member, styles)

      let elem = null
      if (enableDelete === true) {
        elem = (
          <Tag
            className="edit-tag member closable"
            key={tag}
            closable
            onClose={() => this.removeTag(tag)}
          >
            {memberElem}
          </Tag>
        )
      } else {
        elem = (
          <Tag className="edit-tag member" key={tag}>
            {memberElem}
          </Tag>
        )
      }

      if (roomObj != null && member != null && member.type === 'owner') {
        ownerTagsElem.push(elem)
      } else {
        tagsElem.push(elem)
      }
    })

    const addTagElem = (
      <Tag
        className="site-tag-plus"
        tabIndex="0"
        onClick={() => {
          this.showAddMember([...fixedList, ...addList])
        }}
        onKeyDown={this.onTagKeyDown}
      >
        <PlusOutlined /> {i18n.t('chat.setting.newMember')}
      </Tag>
    )

    const ownerElems =
      roomObj != null ? (
        <React.Fragment>
          <div className={styles.subTitle}>Owner</div>
          <div className={styles.ownersWrap}>
            {fixedOwnerTagsElem}
            {ownerTagsElem}
          </div>
        </React.Fragment>
      ) : (
        ''
      )

    return (
      <div className={styles.selectMember}>
        {ownerElems}
        <div className={styles.subTitle}>{i18n.t('chat.member.members')}</div>
        <PerfectScrollbar className={classNames(styles.membersWrap, 'border')} always>
          {fixedTagsElem}
          {tagsElem}
        </PerfectScrollbar>
        {addTagElem}
      </div>
    )
  }
}

export default SelectMemberDef
