import {
  notification,
  message as AntMessage,
} from 'antd'
import forge from 'node-forge'
import conf from 'conf'
import { restLandingClient, clientForLanding } from 'myNet'
import store from 'store'
import i18n from 'i18n'

import Ngql from 'gql/ninegql'

import { ClientJS } from 'clientjs'

import { customFetch } from './services/jwt'

function strToBytes(str) {
  const bytes = new Uint8Array(str.length)
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i)
    bytes[i] = char
  }
  return bytes
}

export function sendEncrypted(apiName, params, successFunc, failFunc) {
  console.log(params)
  const publicKeyURL = '/provisioning/public_key/json'

  restLandingClient.get(publicKeyURL).then((res) => {
    const { version, publicKey } = res.data.data

    const api = `${conf.SERVER.restLandigURL}${apiName}${version}`

    const body0 = JSON.stringify(params)

    const body = forge.util.encodeUtf8(body0)

    console.log(' --- ', body, publicKey)

    let bytes = null

    // chat-test일 때는 encrypte 사용 안함.
    if (
      process.env.REACT_APP_TARGET !== 'log' &&
      conf.SERVER.restURL.indexOf('http://chat-test.rework.so:8001/v1') !== 0
    ) {
      const publicKeyObj = forge.pki.publicKeyFromPem(publicKey)

      const encrypted = publicKeyObj.encrypt(body, 'RSA-OAEP', {
        md: forge.md.sha256.create(),
        mgf1: {
          md: forge.md.sha256.create(),
        },
      })

      bytes = strToBytes(encrypted)
    } else {
      bytes = strToBytes(body)
    }

    const blob = new Blob([bytes], { type: 'application/octet-stream' })

    console.log(blob)

    // const token = store.get('app.user.token')
    // return the headers to the context so httpLink can read them

    const ret = customFetch(api, {
      method: 'POST',
      headers: {
        // authorization: `JWT ${token}`,
        // 'Content-Type': 'text/plain',
        // 'Content-Type': 'application/json',
        'content-type': 'application/x-www-form-urlencoded',
        // 'content-type': 'application/octet-stream',
      },
      body: blob,
    })

    ret
      .then((sres) => {
        console.log(' subscribe - ', sres)

        sres.json().then((data) => {
          console.log(' - ret : ', data)

          if (data.errors != null || data.code !== 200) {
            failFunc(data)
          } else if (successFunc != null) {
            successFunc(data)
          }
        })
      })
      .catch((error) => {
        failFunc(error)
      })
  })
}

export function getDeviceUUID(email) {
  let uuid = store.get('app.devive.uuid')

  if (uuid == null) {
    const client = new ClientJS()
    const fingerprint = client.getFingerprint()
    const curTime = Date.now()

    const md = forge.md.md5.create()
    md.update(`${email}_${fingerprint}_${curTime}`)
    const hex = md.digest().toHex()
    uuid = `RW${hex}`
    store.set('app.devive.uuid', uuid)
  }

  return uuid
}


export function updateAccountInfo(id, sendData, onUpdated, hideResultMsg) {
  const mutation = Ngql.Mutation('updateUserInfo', {
    updateUser: Ngql.Node(
      {
        ok: true,
        errors: true,
      },
      null,
      false,
      {
        userData: Ngql.Var('userData', 'updateUserInput'),
        userGid: Ngql.Var('userGid', 'String'),
      },
    ),
  })

  const variables = {
    userData: {
      // displayName: (displayName == null || displayName.length === 0) ? ' ' : displayName,
      // displayName,
      // avatarUrl,
      ...sendData,
    },
    // userGid: 'VXNlck5vZGU6MTk=',
  }

  if (id != null) {
    variables.userGid = id
  }

  Ngql.GQLObj(mutation, { vars: variables })
    .mutate({}, clientForLanding)
    .then((res) => {
      console.log(res)
      if (hideResultMsg !== true) {
        AntMessage.info(i18n.t('profile.result.modified'))
      }
      if (onUpdated != null) {
        onUpdated()
      }
      return true
    })
    .catch((error) => {
      console.log(error)
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}


const pUtils = {
  sendEncrypted,
  getDeviceUUID,
}

export default pUtils
