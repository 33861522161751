import React from 'react'
import {
  // Button,
  Form,
  Input,
  Tabs,
  Radio,
  Space,
} from 'antd'

import { store as reduxStore } from 'index'
import classNames from 'classnames'
// import { getEnvParam } from 'env'
import i18n from 'i18n'

// import { confirmBase } from 'pages/chat/component/chatUI'
import { getDefaultName } from 'pages/chat/component/chatView/data/cUtil'

import SelectMember from 'pages/chat/component/MemberView/SelectMember0'
// import SelectImage from 'pages/chat/component/SelectImage'

// import messageSVG from 'assets/images/icon-message.svg'
// import mentionSVG from 'assets/images/icon-mention.svg'
// import reactionSVG from 'assets/images/icon-reaction.svg'

import styles from '../style.module.scss'

class RoomSettingDef extends React.Component {
  state = {
    isInitialized: false,
    titleEditable: true,
    // accessRoleDisabled: true,
    // accessRolehidden: false,
    title: '',
    description: '',
    // accessRole: 'private',
    fixedMemberList: [],
    defaultList: [],
    alarmState: 'all',
    defaultTab: null,
  }

  roomInfoRef = React.createRef()

  alarmRef = React.createRef()

  changedData = {}

  componentDidMount() {
    const { roomObj, defaultTab } = this.props

    const { ChatRoom, Member } = roomObj

    let newState = null
    // const userEmail = getEnvParam('userEmail')

    if (ChatRoom.type === 'direct_message') {
      let text = ''

      ChatRoom.members.forEach((member, idx) => {
        const name = getDefaultName(member)

        if (idx === 0) {
          text += name
        } else {
          text += `, ${name}`
        }
      })

      // direct_message에서는 기존 맴버를 제거할 수 없음.
      const fixedList = ChatRoom.members
        .filter((item) => {
          return item.status === 1 // delete 상태가 아닌 것.
        })
        .map((item) => {
          return item.email
        })

      const emailList = []

      newState = {
        isInitialized: true,
        titleEditable: false,
        title: text,
        description: ChatRoom.description,
        // accessRole: ChatRoom.access_role,
        // accessRoleDisabled: true,
        // accessRolehidden: true,
        fixedMemberList: fixedList,
        defaultList: emailList,
      }
    } else {
      // const fixedList = [userEmail]
      const fixedList = ChatRoom.members
        .filter((item) => {
          return item.status === 1 // 자기 자신 제외, delete 상태가 아닌 것.
        })
        .map((item) => {
          return item.email
        })

      const emailList = []

      const reduxState = reduxStore.getState()

      const { user } = reduxState

      const isAdmin = user != null && user.workspace != null && user.workspace.role === 'admin'
      const isOwner =
        (user != null && user.workspace != null && user.workspace.role === 'owner') ||
        Member.type === 'owner'

      console.log(isAdmin, isOwner)

      newState = {
        isInitialized: true,
        titleEditable: true,
        title: ChatRoom.display_name,
        description: ChatRoom.description,
        // accessRole: ChatRoom.access_role,
        // accessRoleDisabled: ChatRoom.type !== 'channel' || (isAdmin !== true && isOwner !== true),
        // accessRolehidden: ChatRoom.type !== 'channel',
        fixedMemberList: fixedList,
        defaultList: emailList,
      }
    }

    if (Member != null && Member.config != null && Member.config.push != null) {
      const { push } = Member.config
      newState.push = {
        ...Member.config.push,
      }
      newState.alarmState = this.getAlarmState(push)
    }

    if (defaultTab != null) {
      newState.defaultTab = defaultTab

      this.onTabChanged(defaultTab)
    } else {
      this.onTabChanged('media')
    }

    this.setState(newState)
  }

  componentWillUnmount() {}

  onTabChanged = (key) => {
    console.log('onTabChanged - ', key)
  }

  getAlarmState = (pushObj) => {
    let ret = 'none'

    if (pushObj.enabled === true) {
      if (pushObj.message === true) {
        ret = 'all'
      } else if (pushObj.mention === true) {
        ret = 'mention'
      }
    }

    return ret
  }

  getAlarmProps = (alarmState) => {
    const push = {
      enabled: false,
      message: false,
      // comment: true,
      reaction: false,
      mention: false,
    }

    switch (alarmState) {
      case 'all':
        push.enabled = true
        push.message = true
        push.reaction = true
        push.mention = true
        break
      case 'mention':
        push.enabled = true
        push.mention = true
        break
      case 'none':
        break
      default:
    }

    return push
  }

  getData = () => {
    console.log(' - selectMemberRef', this.selectMemberRef)
    console.log(' - roomInfoRef', this.roomInfoRef)
    console.log(' - alarmRef', this.alarmRef)

    return this.changedData
  }

  notifyOnChange = () => {
    const { onChange } = this.props
    if (onChange != null) {
      onChange(this.changedData)
    }
  }

  onRoomInfoChanged = (changedValues, allValues) => {
    console.log(' - onRoomInfoChanged - ', changedValues, allValues)
    if (this.changedData.roomInfo == null) {
      this.changedData.roomInfo = { ...allValues }
    } else {
      const { photo } = this.changedData.roomInfo
      this.changedData.roomInfo = { ...allValues }
      this.changedData.roomInfo.photo = photo
    }

    this.notifyOnChange()
  }

  onChangeFile = (file) => {
    if (this.changedData.roomInfo != null) {
      this.changedData.roomInfo.phote = file
    } else {
      this.changedData.roomInfo = {
        photo: file,
      }
    }

    this.notifyOnChange()
  }

  onAlarmChanged = (changedValues, allValues) => {
    console.log(' - onAlarmChanged - ', changedValues, allValues)

    // this.changedData.alarm = allValues

    const { alarmState } = this.state
    if (alarmState !== allValues.alarmState) {
      this.setState({
        alarmState: allValues.alarmState,
      })
    }

    const newPush = this.getAlarmProps(allValues.alarmState)
    this.changedData.alarm = newPush

    this.notifyOnChange()
  }

  onListChange = (list) => {
    console.log(' - onListChange ', list)

    this.changedData.members = list

    this.notifyOnChange()
  }

  // setAccessRole = (accessRole) => {
  //   if (this.roomInfoRef.current != null) {
  //     this.roomInfoRef.current.setFieldsValue({ accessRole })

  //     const fields = this.roomInfoRef.current.getFieldsValue()

  //     this.onRoomInfoChanged({ accessRole }, fields)
  //   }

  //   this.setState({
  //     accessRole,
  //   })
  // }

  // confirmAccessRole = (accessRole, isDisabled) => {
  //   const { title: roomTitle } = this.state

  //   console.log(' - confirmAccessRole : ', accessRole, isDisabled)

  //   let title = ''
  //   let content = ''
  //   let okText = ''

  //   if (accessRole === 'public') {
  //     title = i18n.t('chat.title.changeToPublic')
  //     content = i18n.t('chat.confirm.changeToPublic', { name: roomTitle })
  //     okText = i18n.t('chat.button.changeToPublic')
  //   } else if (accessRole === 'private') {
  //     title = i18n.t('chat.title.changeToPrivate')
  //     content = i18n.t('chat.confirm.changeToPrivate', { name: roomTitle })
  //     okText = i18n.t('chat.button.changeToPrivate')
  //   }

  //   confirmBase({
  //     title,
  //     content,
  //     okText,
  //     onOk: () => {
  //       console.log(' - confirmBase : Ok ')
  //       this.setAccessRole(accessRole)
  //     },
  //   })
  // }

  // onClickAccessRole = (e) => {
  //   console.log(' - onClickAccessRole - ', e)
  // }

  render = () => {
    const { roomObj, onOpenSelectedList } = this.props

    const {
      isInitialized,
      titleEditable,
      title,
      description,
      // accessRole,
      // accessRoleDisabled,
      // accessRolehidden,
      alarmState,
      fixedMemberList,
      defaultList,
      defaultTab,
    } = this.state

    if (isInitialized !== true) {
      return <div />
    }

    const isArchived = roomObj.ChatRoom.archive

    const items = [
      {
        label: i18n.t('chat.setting.tabRoomName'),
        key: 'room-name',
        children: (
          <Form ref={this.roomInfoRef} layout="vertical" onValuesChange={this.onRoomInfoChanged}>
            <Form.Item
              name="displayName"
              initialValue={title}
              rules={[{ required: true, message: '' }]}
            >
              <Input
                placeholder={i18n.t('chat.alt.title')}
                disabled={isArchived || titleEditable !== true}
              />
            </Form.Item>
            <Form.Item name="description" initialValue={description}>
              <Input.TextArea
                autoSize={{ maxRows: 6 }}
                rows={2}
                placeholder={i18n.t('chat.alt.desc')}
                disabled={isArchived}
              />
            </Form.Item>
            {/*
            <Form.Item className={classNames(styles.selectRole, { hidden: accessRolehidden })}>
              <Form.Item
                className={classNames('hidden')}
                name="accessRole"
                initialValue={accessRole}
              >
                <Input type="hidden" />
              </Form.Item>
              <Button
                className={classNames({ hidden: accessRole === 'public' })}
                onClick={() => {
                  this.confirmAccessRole('public', accessRoleDisabled)
                }}
                disabled={accessRoleDisabled}
              >
                {i18n.t('chat.setting.changeToPublic')}
              </Button>
              <Button
                className={classNames({ hidden: accessRole === 'private' })}
                onClick={() => {
                  this.confirmAccessRole('private', accessRoleDisabled)
                }}
                disabled={accessRoleDisabled}
              >
                {i18n.t('chat.setting.changeToPrivate')}
              </Button>
              {accessRoleDisabled === true && (
                <div className={styles.desc}>{i18n.t('chat.message.accessRolePermission')}</div>
              )}
            </Form.Item>
            */}
            {/*
            <Form.Item label={i18n.t('chat.setting.photo')}>
              <SelectImage
                roomObj={roomObj}
                onChange={this.onChangeFile}
              />
            </Form.Item>
            */}
          </Form>
        ),
      },
      {
        label: i18n.t('chat.setting.tabMembers'),
        key: 'members',
        children: (
          <SelectMember
            ref={(ref) => {
              this.selectMemberRef = ref
            }}
            fixedList={fixedMemberList}
            defaultList={defaultList}
            onListChange={this.onListChange}
            roomObj={roomObj}
            enableDelete
            onOpenSelectedList={onOpenSelectedList}
          />
        ),
      },
      {
        label: i18n.t('chat.setting.tabAlarm'),
        key: 'alarm',
        children: (
          <div className={styles.alarmForm}>
            <Form ref={this.alarmRef} layout="vertical" onValuesChange={this.onAlarmChanged}>
              <div className={styles.subTitle}>{i18n.t('chat.title.sendNotification')}</div>
              <Form.Item name="alarmState" initialValue={alarmState}>
                <Radio.Group disabled={isArchived}>
                  <Space direction="vertical" size={0}>
                    <Radio value="all">{i18n.t('chat.button.allNotification')}</Radio>
                    <Radio value="mention">{i18n.t('chat.button.mentionOnly')}</Radio>
                    <Radio value="none">{i18n.t('chat.button.noNotification')}</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Form>
          </div>
        ),
      },
    ]

    const tabProps = {}

    if (defaultTab != null && defaultTab.length > 0) {
      tabProps.singleTab = true
    }

    return (
      <Tabs
        className={classNames(tabProps)}
        items={items}
        defaultActiveKey={defaultTab}
        onChange={this.onTabChanged}
      />
    )
  }
}

const RoomSetting = RoomSettingDef

export default RoomSetting
