import React from 'react'
import { ConfigProvider, Spin } from 'antd'
import { connect } from 'react-redux'

import korea from 'antd/es/locale/ko_KR'
import english from 'antd/es/locale/en_US'
import french from 'antd/es/locale/fr_FR'
import russian from 'antd/es/locale/ru_RU'
import chinese from 'antd/es/locale/zh_CN'
import Japanese from 'antd/es/locale/ja_JP'

import i18n from 'i18n'

const locales = {
  'ko-KR': korea,
  'en-US': english,
  'fr-FR': french,
  'ru-RU': russian,
  'zh-CN': chinese,
  'ja-JP': Japanese,
}

@connect(({ settings }) => ({ settings }))
class Localization extends React.Component {
  state = {
    localeInitialized: false,
  }

  componentDidMount() {
    const { dispatch } = this.props
    const initLocale = () => {
      this.state.localeInitialized = true
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'locale',
          value: i18n.language,
        },
      })
    }
    if (i18n.isInitialized === true) {
      initLocale()
    } else {
      i18n.on('initialized', () => {
        initLocale()
      })
    }
  }

  render() {
    const { localeInitialized } = this.state
    const {
      children,
      settings: { locale },
    } = this.props

    const currentLocale = locales[locale]

    const component =
      localeInitialized === true ? (
        <ConfigProvider locale={currentLocale.localeAntd}>{children}</ConfigProvider>
      ) : (
        <Spin />
      )

    return component
  }
}
export default Localization
