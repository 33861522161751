import React, { Fragment } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
// import Loader from 'components/cleanui/layout/Loader'
import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'
import EmptyLayout from './Empty'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
  empty: EmptyLayout,
}

const mapStateToProps = ({ user }) => ({ user })
// let previousPath = ''
let previousPathname = ''

const Layout = ({ user, children }) => {
  const location = useLocation();

  // NProgress & ScrollTop Management
  // const { pathname, search } = location;
  const { pathname } = location

  // const currentPath = pathname + search
  if (previousPathname !== pathname) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    // previousPath = currentPath;
    previousPathname = pathname
  }, 300)

  // Layout Rendering
  let needAuth = true;
  const getLayout = () => {
    if (pathname === '/') {
      return 'public'
    }
    if (
      pathname.indexOf('/downloadFile') === 0
    ) {
      return 'empty'
    }
    if (
      pathname.indexOf('/redirect') === 0
    ) {
      needAuth = false;
      return 'empty'
    }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    return 'main'
  }

  const layout = getLayout();
  const Container = Layouts[layout]
  const isUserAuthorized = user.authorized
  const isUserLoading = user.loading
  const isAuthLayout = layout === 'auth'

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    if (needAuth && !isAuthLayout && !isUserAuthorized) {
      return <Navigate to="/auth/login" />
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="Admin Console | %s" title="Admin Console" />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default connect(mapStateToProps)(Layout)
