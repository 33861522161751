/* eslint-disable global-require */
import React from 'react'
// import React, { lazy, Suspense } from 'react'
import { Route, Navigate, Routes, useLocation, useParams, useNavigate } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import { getConfig } from 'env'
import { history as globalHistory } from 'index'

import { parseSearchParam, processNParam } from 'utils'

import Layout from 'layouts'

export function withRouter(Component, options) {
  return (props) => {
    const newProps = {
      ...props
    };

    newProps.location = useLocation();
    newProps.navigate = useNavigate();
    newProps.history = globalHistory;
    newProps.match = {
      params: useParams(),
      path: newProps.location.pathname
    };

    if (options != null && options.navigate === true) {
      newProps.navigate = useNavigate();
    }

    return (
      <Component {...newProps} />
    )
  };
}

const mapStateToProps = ({ menu, settings }) => ({
  myRoutes: menu.routerData,
  routerAnimation: settings.routerAnimation,
})

@connect(mapStateToProps)
class Router extends React.Component {
  constructor(props) {
    super(props)
    // store.set('app.router.location', '');

    const { history } = props
    this.unsubscribeFromHistory = history.listen(this.handleLocationChange)
    this.handleLocationChange(history.location)
  }

  // componentDidMount() {
  // }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory()
  }

  handleLocationChange = (e) => {
    const { history } = this.props
    console.log('ROUTE CHANGED', e, history, )

    parseSearchParam()
    processNParam()
  }

  // const Router = ({ history, routerAnimation }) => {
  render = () => {
    const { routerAnimation, myRoutes, location } = this.props

    const defaultPage = getConfig('defaultPage')

    return (
      <Layout>
        <SwitchTransition>
          <CSSTransition
            key={location.pathname}
            appear
            classNames={routerAnimation}
            timeout={routerAnimation === 'none' ? 0 : 300}
          >
            <Routes location={location}>
              <Route exact path="/" element={<Navigate to={defaultPage} />} />
              {myRoutes.map(({ path, Component, exact }) => {
                return (
                  <Route
                    path={path}
                    key={path}
                    exact={exact}
                    element={(
                      <div className={routerAnimation}>
                        <Component />
                      </div>
                    )}
                  />
                )
              })}
              <Route exact path="*" element={<Navigate to="/auth/404" />} />
            </Routes>
          </CSSTransition>
        </SwitchTransition>
      </Layout>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Router))
