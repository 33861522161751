import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'

import { getConfig } from 'env'
import { getSearchParams } from 'utils'

import store from 'store'

// import * as firebase from 'services/firebase'
import * as jwt from 'services/jwt'
import * as adminOtp from 'services/admin-otp'
import * as landing from 'services/landing'
import actions from './actions'

const mapAuthProviders = {
  // firebase: {
  //   login: firebase.login,
  //   register: firebase.register,
  //   currentAccount: firebase.currentAccount,
  //   logout: firebase.logout,
  // },
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
  landing: {
    login: landing.login,
    register: landing.register,
    currentAccount: landing.currentAccount,
    logout: landing.logout,
  },
}

export function* INIT_ADMIN_LOGIN() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      step: 0,
    },
  })
}

export function* OTP_EMAIL_SENDED() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      step: 2,
    },
  })
}

export function* REQ_LOGIN_CODE({ payload }) {
  const { email } = payload
  yield console.log(email)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      step: 1, // button is clicked.
    },
  })

  const ret = yield call(adminOtp.reqOtpCode, email)
  if (ret != null && ret.success === true) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        step: 2, // mail is sended.
        otpExpiresIn: ret.otp_expires_in,
      },
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        step: 0, // 초기 상태로.
      },
    })
  }
}

export function* LOGIN_ADMIN({ payload }) {
  const { email, loginCode } = payload
  yield console.log(email, loginCode)

  yield put({
    type: 'user/SET_STATE',
    payload: {
      step: 3, // login request sended.
      loading: true,
    },
  })

  const success = yield call(adminOtp.loginAdmin, email, loginCode)
  if (success === true) {
    // 로그인 성공하면, 사용자 정보 로딩 후, dashboard 페이지로 이동.
    store.remove('app.menu.selectedKeys')

    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })

    yield history.push('/')

    // notification.success({
    //   message: 'Logged In',
    //   description: 'You have successfully logged in!',
    // })

    // yield put({
    //   type: 'user/SET_STATE',
    //   payload: {
    //     step: 0, // mail is sended.
    //   },
    // })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        step: 2, // mail is sended.
        loading: false,
      },
    })
  }
}

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select((state) => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].login, email, password)
  if (success) {

    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })

    const onLoadAccount = () => {
      const params = getSearchParams()
      const defaultPage = getConfig('defaultPage') || '/'
      const target = params.path != null ? window.decodeURIComponent(params.path) : defaultPage
      history.push(target);
    };

    yield onLoadAccount();

    // notification.success({
    //   message: 'Logged In',
    //   description: 'You have successfully logged in!',
    // })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* OAUTHLOGIN({ payload }) {
  // yield put({
  //   type: 'user/LOAD_CURRENT_ACCOUNT',
  // })
  // yield history.replace('/plain/waiting')
  yield put({
    type: 'user/LOAD_CURRENT_ACCOUNT',
  })

  const defaultPage = getConfig('defaultPage') || '/'
  const target = payload && payload.target ? payload.target : defaultPage
  history.push(target);

  // notification.success({
  //   message: 'Logged In',
  //   description: 'You have successfully logged in!',
  // })
}

export function* REGISTER({ payload }) {
  const { email, password, name } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select((state) => state.settings)
  const success = yield call(mapAuthProviders[authProvider].register, email, password, name)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push('/')
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select((state) => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  if (response) {
    const { id, email, name, avatar, role, serverType, workspace } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name,
        email,
        avatar,
        role,
        authorized: true,
        serverType,
        workspace,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  const { authProvider } = yield select((state) => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
  // yield history.push('/');
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.INIT_ADMIN_LOGIN, INIT_ADMIN_LOGIN),
    takeEvery(actions.REQ_LOGIN_CODE, REQ_LOGIN_CODE),
    takeEvery(actions.OTP_EMAIL_SENDED, OTP_EMAIL_SENDED),
    takeEvery(actions.LOGIN_ADMIN, LOGIN_ADMIN),
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.OAUTHLOGIN, OAUTHLOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
