import React from 'react'
import { Tag, Input, Button } from 'antd'
// import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons'
import PerfectScrollbar from 'react-perfect-scrollbar-z'

import classNames from 'classnames'
import i18n from 'i18n'

import { searchMember } from 'pages/chat/component/chatView/data/adminRoomUtil'
import { getHighlightMemberElem, getMemberElem } from 'pages/chat/component/MemberView/memberUtil'
import ShortMemberList from 'pages/chat/component/ShortMemberList'

import iconSearchSVG from 'assets/images/icon-search.svg'
import removeButtonSVG from 'assets/images/button-remove.svg'
import expandButtonSVG from 'assets/images/button-expand.svg'
import collapseButtonSVG from 'assets/images/button-collapse.svg'

import dataManager from '../chatView/data/dataManager'

import styles from '../style.module.scss'

class SelectMemberDef extends React.Component {
  state = {
    fixedList: [],
    addList: [],
    searchResults: [],
    searchStr: null,
    selectedVisible: false,
  }

  searchTimer = null

  selectedList = []

  selectedMap = {}

  tempMemberMap = {}

  scrollRef = React.createRef()

  resultRef = React.createRef()

  componentDidMount() {
    const { fixedList, defaultList } = this.props

    const newState = {}
    if (fixedList != null) {
      newState.fixedList = [...fixedList]
    }
    if (defaultList != null) {
      newState.addList = defaultList.filter((item) => {
        return fixedList == null || fixedList.indexOf(item) < 0
      })

      newState.addList.forEach((email) => {
        this.selectedMap[email] = true
        this.selectedList.push(email)
      })
    }
    if (defaultList != null || fixedList != null) {
      this.setState(newState)
    }

    setTimeout(() => {
      this.onSearch('')
    }, 200)
  }

  componentWillUnmount() {}

  addMemberList = (emailList) => {
    const { onListChange } = this.props
    const { fixedList, addList } = this.state

    console.log('addMemberList - ', emailList)

    if (emailList == null || emailList.length === 0) {
      return
    }

    const curList = [...fixedList, ...addList]

    emailList.forEach((email) => {
      if (curList.indexOf(email) < 0) {
        addList.push(email)
      }
    })

    if (onListChange != null) {
      const tmpMemberList = []
      const changeList = [...fixedList, ...addList].sort()
      const tmpKeys = Object.keys(this.tempMemberMap)
      if (tmpKeys.length > 0) {
        changeList.forEach((item) => {
          const member = this.tempMemberMap[item]
          if (member != null) {
            tmpMemberList.push(member)
          }
        })
      }
      onListChange(changeList, tmpMemberList, fixedList, addList)
    }

    this.setState({
      addList: [...addList],
    })

    if (this.scrollRef.current != null) {
      // const w = 60 * addList.length + 8
      // this.contentElem.style.width = `${w}px`
      this.scrollRef.current.update()
    }
  }

  innerSearchMember = (str, onGetList) => {
    const { memberList, exceptedList } = this.props
    const { fixedList } = this.state

    const txt = str || ''

    const exceptList = exceptedList || []

    if (txt.length === 0) {
      if (onGetList != null) {
        const curList = [...fixedList]

        const resultMembers = dataManager.globalMemberList.filter((member) => {
          return curList.indexOf(member.email) < 0 && exceptList.indexOf(member.email) < 0
        })

        if (resultMembers.length > 0) {
          onGetList(resultMembers)
          return
        }
      }
    }

    searchMember(txt)
      .then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          if (res.data.data != null) {
            let members = [...res.data.data]
            const curList = [...fixedList]

            members = members.filter((member) => {
              return curList.indexOf(member.email) < 0 && exceptList.indexOf(member.email) < 0
            })

            if (memberList != null) {
              memberList.forEach((item) => {
                members.some((member, idx) => {
                  if (member.email === item.email) {
                    members.splice(idx, 1)
                    return true
                  }
                  return false
                })
              })
            }

            if (onGetList != null) {
              onGetList(members)
            }
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  removeMember = (email) => {
    const { onListChange } = this.props
    const { fixedList, addList } = this.state

    const pos = addList.indexOf(email)
    if (pos >= 0) {
      addList.splice(pos, 1)

      if (onListChange != null) {
        const tmpMemberList = []
        const changeList = [...fixedList, ...addList].sort()
        const tmpKeys = Object.keys(this.tempMemberMap)
        if (tmpKeys.length > 0) {
          changeList.forEach((item) => {
            const member = this.tempMemberMap[item]
            if (member != null) {
              tmpMemberList.push(member)
            }
          })
        }
        onListChange(changeList, tmpMemberList, fixedList, addList)
      }

      this.setState({
        addList: [...addList],
      })

      if (addList.length === 0) {
        this.toggleSelectedList(false)
      }

      if (this.scrollRef.current != null) {
        // const w = 60 * addList.length + 8
        // this.contentElem.style.width = `${w}px`
        this.scrollRef.current.update()
      }
    }
  }

  onSearch = (str) => {
    this.innerSearchMember(str, (list) => {
      // const { roomObj } = this.props

      console.log(' - innerSearchMember : ', list)

      // const memberMap =
      //   roomObj != null && roomObj.roomMemberMap != null ? roomObj.roomMemberMap : null

      // const resultList = list.filter((member) => {
      //   if (memberMap != null) {
      //     const memberObj = memberMap[member.email]
      //     return memberObj != null
      //   }
      //   return true
      // })

      const resultList = list

      this.setState({
        searchResults: resultList,
        searchStr: str,
      })

      if (this.resultRef != null && this.resultRef.current != null) {
        this.resultRef.current.element.scrollTop = 0
        this.resultRef.current.update()
      }
    })
  }

  setMemberSelected = (email, value) => {
    if (value !== true) {
      this.selectedMap[email] = false
      const pos = this.selectedList.indexOf(email)
      this.selectedList.splice(pos, 1)

      this.removeMember(email)
    } else {
      this.selectedMap[email] = true
      this.selectedList.push(email)

      this.addMemberList([email])
    }

    this.forceUpdate()
  }

  onToggleMember = (member) => {
    const isSelected = this.selectedMap[member.email] === true

    this.setMemberSelected(member.email, !isSelected)
  }

  onClickClearAllBtn = () => {
    this.selectedList.forEach((email) => {
      this.selectedMap[email] = false
      this.removeMember(email)
    })
    this.selectedList = []
  }

  toggleSelectedList = (visible) => {
    const { onOpenSelectedList } = this.props
    const { selectedVisible } = this.state

    const newVisible = visible != null ? visible : !selectedVisible

    this.setState({
      selectedVisible: newVisible,
    })

    if (onOpenSelectedList != null) {
      onOpenSelectedList(newVisible)
    }
  }

  onTagKeyDown = (e) => {
    console.log('onTagKeyDown - ', e)
  }

  getElemObj = () => {
    const { roomObj, enableDelete, hideFixedList } = this.props
    const { addList, fixedList } = this.state

    const fixedOwnerTagsElem = []
    const fixedTagsElem = []

    const ownerTagsElem = []
    const tagsElem = []

    const memberMap =
      roomObj != null && roomObj.roomMemberMap != null
        ? roomObj.roomMemberMap
        : dataManager.globalMemberMap

    fixedList.forEach((tag) => {
      const member = memberMap[tag]
      const memberElem = getMemberElem(tag, member, styles, true)
      const elem = (
        <Tag className="edit-tag member fixed" key={tag}>
          {memberElem}
        </Tag>
      )
      if (roomObj != null && member != null && member.type === 'owner') {
        fixedOwnerTagsElem.push(elem)
      } else {
        fixedTagsElem.push(elem)
      }
    })

    addList.forEach((tag) => {
      const member = memberMap[tag]
      const memberElem = getMemberElem(tag, member, styles, true)

      let elem = null
      if (enableDelete === true) {
        elem = (
          <Tag
            className="edit-tag member closable"
            key={tag}
            closable
            closeIcon={<img src={removeButtonSVG} alt="remove" />}
            onClose={() => this.setMemberSelected(tag, false)}
          >
            {memberElem}
          </Tag>
        )
      } else {
        elem = (
          <Tag className="edit-tag member" key={tag}>
            {memberElem}
          </Tag>
        )
      }

      if (roomObj != null && member != null && member.type === 'owner') {
        ownerTagsElem.push(elem)
      } else {
        tagsElem.push(elem)
      }
    })

    const title = i18n.t('chat.title.selected', { count: addList.length })

    const ownerElems =
      roomObj != null ? (
        <React.Fragment>
          <div className={styles.subTitle}>Owner</div>
          <div className={styles.ownersWrap}>
            {fixedOwnerTagsElem}
            {ownerTagsElem}
          </div>
        </React.Fragment>
      ) : (
        ''
      )

    const unSelectAllBtn = (
      <Button className={styles.unSelectAll} type="text" onClick={this.onClickClearAllBtn}>
        {i18n.t('chat.button.clearAll')}
      </Button>
    )

    const addedElems = (
      <React.Fragment>
        <span className="ant-modal-confirm-title selectedTitle">
          {title}
          {unSelectAllBtn}
        </span>
        <div className="ant-modal-confirm-body">
          <div className="ant-modal-confirm-content">
            <PerfectScrollbar
              refScroll={this.scrollRef}
              className={classNames(styles.membersWrap, styles.selected)}
            >
              <div
                ref={(ref) => {
                  this.contentElem = ref
                }}
              >
                {hideFixedList !== true && fixedTagsElem}
                {tagsElem}
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </React.Fragment>
    )

    return {
      ownerElems,
      addedElems,
    }
  }

  getMemberListElem = () => {
    const { roomObj, hideFixedList } = this.props
    const { addList, fixedList, selectedVisible } = this.state

    const btnIcon =
      selectedVisible === true ? (
        <img src={collapseButtonSVG} alt="collapse" />
      ) : (
        <img src={expandButtonSVG} alt="expand" />
      )

    const extraElem = (
      <Button
        className={styles.extraBtn}
        type="default"
        size="large"
        htmlType="button"
        icon={btnIcon}
        onClick={() => {
          this.toggleSelectedList()
        }}
      >
        {/* i18n.t('common.button.viewAll') */}
      </Button>
    )

    const allList = hideFixedList !== true ? [...addList, ...fixedList] : [...addList]

    if (allList.length > 0 || selectedVisible === true) {
      return (
        <ShortMemberList
          roomObj={roomObj}
          addList={allList}
          tempMemberMap={this.tempMemberMap}
          extraElem={extraElem}
        />
      )
    }
    return ''
  }

  render = () => {
    const { searchResults, searchStr, selectedVisible } = this.state

    const elemObj = this.getElemObj()

    const memberListElem = this.getMemberListElem()

    const resultElems = searchResults.map((member) => {
      if (dataManager.globalMemberMap[member.email] == null) {
        this.tempMemberMap[member.email] = member
      }

      const elem = getHighlightMemberElem(member.email, member, styles, searchStr)
      return (
        <Tag.CheckableTag
          className="edit-tag member selectable"
          key={member.email}
          onChange={() => {
            this.onToggleMember(member)
          }}
          checked={this.selectedMap[member.email] === true}
        >
          {elem}
        </Tag.CheckableTag>
      )
    })

    const selectedProps = {
      visible: selectedVisible === true,
    }

    return (
      <div className={classNames(styles.selectMember, styles.type2)}>
        {/* elemObj.ownerElems */}
        <div className={styles.subTitle}>{i18n.t('chat.member.members')}</div>
        {/* elemObj.addedElems */}
        {memberListElem}
        <Input.Search
          placeholder="Search"
          onSearch={this.onSearch}
          enterButton={<img src={iconSearchSVG} alt="search" />}
        />
        <div className={styles.subTitle}>{i18n.t('chat.setting.chooseMember')}</div>
        <PerfectScrollbar
          refScroll={this.resultRef}
          className={classNames(styles.membersWrap, 'border')}
        >
          {resultElems}
        </PerfectScrollbar>
        <div className={classNames(styles.selectedMembers, selectedProps)}>
          {elemObj.addedElems}
        </div>
      </div>
    )
  }
}

export default SelectMemberDef
