const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  OAUTHLOGIN: 'user/OAUTHLOGIN',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  INIT_ADMIN_LOGIN: 'user/INIT_ADMIN_LOGIN', // admin login 초기화.
  REQ_LOGIN_CODE: 'user/REQ_LOGIN_CODE', // /otp/send_admin_email
  OTP_EMAIL_SENDED: 'user/EMAIL_SENDED', // opt email 발송 상태.
  LOGIN_ADMIN: 'user/LOGIN_ADMIN', // /auth/loginwithemail
}

export default actions
