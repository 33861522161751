import React from 'react'
import { Tooltip } from 'antd'
import { renderToStaticMarkup } from 'react-dom/server'
import { Initial } from 'react-initial'

import moment from 'moment'

import i18n from 'i18n'
import { timeFormat } from 'utils'
import parse from 'html-react-parser'

import { getEnvParam } from 'env'
import dataMgr, { initRoomObj } from 'pages/chat/component/chatView/data/dataManager'
import imageDB from 'pages/chat/component/chatView/db/imageDB'
import { creactReactObjMgr } from 'pages/chat/component/chatView/data/dataUtil'
import attachmentManager from 'pages/chat/component/chatView/data/attachmentManager'
import classNames from 'classnames'

import { xhrGetRoomFile } from 'pages/chat/component/chatView/data/adminRoomUtil'

import calendarSVG from 'assets/images/room_type/property-chat-event.svg'
import emailSVG from 'assets/images/room_type/property-chat-email.svg'
import channelSVG from 'assets/images/room_type/property-chat-channel.svg'
import privateChannelSVG from 'assets/images/room_type/property-chat-private-channel.svg'
import dmSVG from 'assets/images/room_type/property-chat-dm.svg'
import customReactionSvg from 'assets/images/menu-reaction.svg'

const customReactionIcon = <img src={customReactionSvg} alt="custom reaction" />

window.moment = moment

const getUTCTime = (time) => {
  let utcTime = time
  if (time != null && time.length > 0 && time.indexOf('Z') === -1) {
    utcTime = `${time}Z`
  }
  return utcTime
}

const getMsgTextJson = (type, content) => {
  const obj = {
    type,
    content,
  }

  return JSON.stringify(obj)
}

export function getHighlightedText(str, highlightText) {
  const txt = str != null ? str : ''
  if (highlightText == null || highlightText.length === 0) {
    return str
  }

  const lowertxt = txt.toLowerCase()
  const lowerHtxt = highlightText.toLowerCase()
  const pos = lowertxt.indexOf(lowerHtxt)

  if (pos >= 0) {
    const preText = txt.substring(0, pos)
    const hText = txt.substring(pos, pos + highlightText.length)
    const nextText = txt.substring(pos + highlightText.length)

    return (
      <span>
        {preText}
        <span className="highlight">{hText}</span>
        {nextText}
      </span>
    )
  }

  return <span>{str}</span>
}

export const colors = [
  '#A9624A',
  '#D06B64',
  '#F83A22',
  '#FA573C',
  '#FF7537',
  '#FF950F',
  '#00BB65',
  '#008247',
  '#43A608',
  '#6BBC00',
  '#FFA600',
  '#FFBC0A',
  '#67AC8F',
  '#61BCC4',
  '#7AA4C8',
  '#4986E7',
  '#6D6FDE',
  '#8562D6',
  '#8D8D8D',
  '#AB8E92',
  '#A7767D',
  '#F46594',
  '#F46594',
  '#7446B7',
]

export function getInitialAvatar(name, email) {
  if (name == null && email == null) {
    return null
  }

  const avatarProps = {
    // charCount: 2,
    charCount: 1,
  }

  let tmp = []
  if (name != null) {
    tmp = name.split(/[\s\r\n,.]+/gm)
  } else {
    const pos = email.indexOf('@')
    tmp = email.substring(0, pos - 1).split(/[\s\r\n,.]+/gm)
    // const eText = email.substring(pos);
  }

  let str = ''
  tmp.forEach((txt) => {
    if (/^(\p{L}|\d)/gu.test(txt) === true) {
      str += txt.charAt(0)
    }
  })

  str = str.toUpperCase()

  avatarProps.name = str

  const seed = 0
  avatarProps.color = colors[Math.floor((str.charCodeAt(0) + seed) % colors.length)]

  return <Initial {...avatarProps} />
}

function unicodeCharAt(str, index) {
  const first = str.charCodeAt(index)
  let second

  if (first >= 0xd800 && first <= 0xdbff && str.length > index + 1) {
    second = str.charCodeAt(index + 1)

    if (second >= 0xdc00 && second <= 0xdfff) {
      return str.substring(index, index + 2)
    }
  }

  return str[index]
}

function unicodeSlice(str, start, end, words) {
  let accumulator = ''
  let character
  let stringIndex = 0
  let unicodeIndex = 0
  let nextSpace = -1
  const { length } = str

  // Remove any leading/trailing spaces
  str = str.trim()

  while (stringIndex < length) {
    character = unicodeCharAt(str, stringIndex)

    if (unicodeIndex >= start && unicodeIndex < end) {
      accumulator += character
    } else {
      break
    }

    stringIndex += character.length
    unicodeIndex += 1

    // Find the next space offset from the previous finding
    nextSpace = words ? str.indexOf(' ', nextSpace + 1) : -1
    stringIndex = nextSpace > 0 ? nextSpace + 1 : stringIndex
  }

  return accumulator
}

export function getInitialAvatarSrcByProps(props) {
  const ownProps = {
    name: 'Name',
    color: null,
    seed: 0,
    charCount: 1,
    textColor: '#ffffff',
    height: 100,
    width: 100,
    fontSize: 60,
    fontWeight: 400,
    fontFamily:
      'HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif',
    radius: 0,
    ...props,
  }

  const {
    width,
    height,
    textColor,
    fontFamily,
    fontSize,
    fontWeight,
    charCount,
    useWords,
    name,
    color,
    seed,
    radius: borderRadius,
  } = ownProps

  let initial = unicodeSlice(name || 'Name', 0, charCount || 1, useWords || false).toUpperCase()
  let backgroundColor =
    color !== null ? color : colors[Math.floor((initial.charCodeAt(0) + seed) % colors.length)]

  if (name.length === 0) {
    initial = ''
    ;[backgroundColor] = colors
  }

  const InitialSvg = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      pointerEvents="none"
      {...{
        width,
        height,
        style: {
          width,
          height,
          backgroundColor,
          borderRadius,
        },
      }}
    >
      <text
        y="50%"
        x="50%"
        dy="0.35em"
        pointerEvents="auto"
        fill={textColor}
        fontFamily={fontFamily}
        textAnchor="middle"
        style={{ fontSize, fontWeight }}
      >
        {initial}
      </text>
    </svg>
  )

  const encodedStr = encodeURIComponent(renderToStaticMarkup(<InitialSvg />)).replace(
    /%([0-9A-F]{2})/g,
    (match, p1) => {
      return String.fromCharCode(`0x${p1}`)
    },
  )

  const imgStr = window.btoa(encodedStr)
  // const imgStr = window.btoa(unescape(encodeURIComponent(renderToStaticMarkup(<InitialSvg />))));
  const svgHtml = `data:image/svg+xml;base64,${imgStr}`

  return svgHtml
}

export function getInitialAvatarSrc(name, email, props) {
  if (name == null && email == null) {
    return null
  }

  const avatarProps = {
    ...props,
    // charCount: 2,
    charCount: 1,
  }

  let tmp = []
  if (name != null) {
    tmp = name.split(/[\s\r\n,.]+/gm)
  } else {
    const pos = email.indexOf('@')
    tmp = email.substring(0, pos - 1).split(/[\s\r\n,.]+/gm)
    // const eText = email.substring(pos);
  }

  let str = ''
  tmp.forEach((txt) => {
    if (/^(\p{L}|\d)/gu.test(txt) === true) {
      str += txt.charAt(0)
    }
  })

  avatarProps.name = str

  return getInitialAvatarSrcByProps(avatarProps)
}

const avatarMap = {}

const iconMap = {
  calendar: <img className="room-type calendar" src={calendarSVG} alt="calendar" />,
  mail: <img className="room-type mail" src={emailSVG} alt="email" />,
  channel: <img className="room-type channel" src={channelSVG} alt="channel" />,
  private: (
    <img className="room-type channel private" src={privateChannelSVG} alt="private channel" />
  ),
  direct_message: <img className="room-type direct-message" src={dmSVG} alt="direct message" />,
}

function hashCode(s) {
  let h
  for (let i = 0; i < s.length; i += 1)
    // eslint-disable-next-line no-bitwise
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0

  return h
}

export function getMemberName(member, emailFunc, checkSelf) {
  const myEmail = getEnvParam('userEmail')

  if (member.email === myEmail && checkSelf !== false) {
    return i18n.t('chat.member.myself')
  }

  if (member.display_name == null) {
    if (emailFunc != null) {
      return emailFunc(member.email)
    }
    return member.email
  }

  return member.display_name
}

const getEmailName = (email) => {
  const pos = email.indexOf('@')
  return email.substring(0, pos)
}

export function getDefaultName(member, email, checkSelf) {
  if (member != null) {
    return getMemberName(member, getEmailName, checkSelf)
  }

  return getEmailName(email)
}

export function getMemberInfo(member, email, avatarProps, checkSelf) {
  const name = getDefaultName(member, email, checkSelf)

  let avatarSrc = null
  if (member != null) {
    avatarSrc = member.avatar_url
  }

  if (avatarSrc == null || avatarSrc.trim().length === 0) {
    const avatarName = getDefaultName(member, email, false)
    avatarSrc = getInitialAvatarSrc(avatarName, email, avatarProps)
  }

  return {
    name,
    avatarSrc,
  }
}

export function getShortMemberListElem(roomObj, addList, extraElem, styles) {
  const memberMap =
    roomObj != null && roomObj.roomMemberMap != null
      ? roomObj.roomMemberMap
      : dataMgr.globalMemberMap

  if (memberMap != null) {
    // const userEmail = getEnvParam('userEmail');

    const memberList = addList.map((tag) => {
      const member = memberMap[tag]
      return member
    })

    const entries = memberList
    // .filter((item) => {
    //   return item.status === 1 // && item[1].email !== userEmail
    // })

    // if (entries.length === 0) {
    //   return null;
    // }

    let imgs = null
    let descElem = null
    const descProps = {}

    if (entries.length > 0) {
      imgs = entries.slice(0, 3).map((item) => {
        const member = item
        const key = item.email

        const memberInfo = getMemberInfo(member, member.email, null)

        const { avatarSrc, name } = memberInfo

        const memberProps = {
          member: true,
        }
        if (styles != null) {
          memberProps[styles.member] = true
        }

        return (
          <span className={classNames(memberProps)} key={key}>
            <img src={`${avatarSrc}`} alt={name} />
          </span>
        )
      })

      const name = getDefaultName(entries[0])
      const nameHtml = `<span class='nameWrap'><span class='name'>${name}</span></span>`

      let descStr = ''
      if (entries.length === 1) {
        descStr = nameHtml
        descProps.singleMember = true
      } else if (entries.length === 2) {
        descStr = i18n.t('chat.member.desc1', { name: nameHtml, count: 1 })
      } else {
        descStr = i18n.t('chat.member.desc2', { name: nameHtml, count: entries.length - 1 })
      }

      descElem = parse(descStr)
    }

    const memberListElem = (
      <div
        role="button"
        className={classNames(styles.memberList, styles.shortType)}
        tabIndex="0"
        onKeyDown={() => {}}
        onClick={() => {}}
        key="memberList"
      >
        {imgs}
        <span className={classNames(styles.desc, descProps)}>{descElem}</span>
        {extraElem}
      </div>
    )

    return memberListElem
  }
  return null
}

function getPhotoFromApi(roomId, digest, onLoaded) {
  xhrGetRoomFile(roomId, digest)
    .then(({ xhr, event }) => {
      console.log('xhrGetRoomFile', xhr, event)
      const blob = xhr.response

      // const str = xhr.getResponseHeader('content-disposition');
      const mimetype = xhr.getResponseHeader('file-content-type')

      // console.log('xhrGetRoomFile', str, mimetype);

      processImageBlob(blob, 'filename', mimetype, onLoaded)
    })
    .catch((err) => {
      console.log('error - ', err)

      if (onLoaded != null) {
        onLoaded(null)
      }
    })
}

export function getPhoto(roomId, digest) {
  const promise = new Promise((resolve, reject) => {
    imageDB
      .getData(digest)
      .then((data) => {
        console.log(' --- image data --- ', data)

        resolve(data.url)
      })
      .catch(() => {
        getPhotoFromApi(roomId, digest, (res) => {
          imageDB
            .addData(digest, res.blob)
            .then((data) => {
              resolve(data.url)
            })
            .catch((err) => {
              reject(err)
            })
        })
      })
  })

  return promise
}

export function getMembersName(roomId, roomObj) {
  let room = null

  if (roomObj != null) {
    room = roomObj
  } else {
    const { roomMap } = dataMgr
    room = roomMap[roomId]
  }

  let tooltipTxt = ''

  const userEmail = getEnvParam('userEmail')

  const roomMembers = getRoomMembers(room);

  roomMembers.forEach((item) => {
    if (item.email === userEmail) {
      // 자기 자신의 이미지는 제외함.
      return
    }

    if (item.status > 0) {
      const name = getMemberName(item)
      if (tooltipTxt.length > 0) {
        tooltipTxt = `${tooltipTxt}, ${name}`
      } else {
        tooltipTxt = name
      }
    }
  })

  return tooltipTxt
}

export function getAvatar(roomId, roomObj) {
  if (avatarMap[roomId] == null) {
    avatarMap[roomId] = {
      loadedTime: 0,
      elem: '',
    }
  }
  const avatarObj = avatarMap[roomId]

  let room = null
  if (roomObj != null) {
    initRoomObj(roomObj)
    room = roomObj
  } else {
    const { roomMap } = dataMgr
    room = roomMap[roomId]
  }

  if (room != null && room.loadedTime != null) {
    if (avatarObj.loadedTime < room.loadedTime) {
      const roomMembers = getRoomMembers(room);

      const cnt = roomMembers.length - 1

      const elems = []

      const userEmail = getEnvParam('userEmail')

      let index = 0
      roomMembers.forEach((item) => {
        if (item.email === userEmail) {
          // 자기 자신의 이미지는 제외함.
          return
        }
        const imgProps = {}
        let avatarSrc = item.avatar_url
        const alt = '' // getName(item)

        if (cnt === 1) {
          imgProps.width = 100
          imgProps.height = 100
        } else if (cnt === 2 || index === 0) {
          imgProps.width = 50
          imgProps.height = 100
        } else if (index > 0) {
          imgProps.width = 100
          imgProps.height = 100
        }

        if (avatarSrc == null) {
          avatarSrc = getInitialAvatarSrc(item.display_name, item.email, imgProps)
        }

        if (index < 3) {
          elems.push(<img src={`${avatarSrc}`} alt={alt} key={item.email} />)
        }

        index += 1
      })

      const tooltipTxt = getMembersName(roomId, roomObj)

      const roomType = room.ChatRoom.type
      if (roomType === 'direct_message') {
        avatarObj.elem = (
          <Tooltip title={tooltipTxt}>
            <div className={classNames('roomIcon', 'kit__utils__avatar')} data-cnt={elems.length}>
              {elems}
            </div>
          </Tooltip>
        )
      } else {
        let typeIcon = ''
        if (roomType != null) {
          if (roomType === 'channel' && room.ChatRoom.access_role === 'private') {
            typeIcon = iconMap.private
          } else if (iconMap[roomType] != null) {
            typeIcon = iconMap[roomType]
          }
        }

        if (room.ChatRoomKey != null) {
          const hCode = hashCode(room.ChatRoomKey.primary_id)

          const colIdx = Math.abs(hCode) % 16

          avatarObj.elem = (
            <Tooltip title={tooltipTxt}>
              <div
                className={classNames('roomIcon', 'kit__utils__avatar')}
                style={{ backgroundColor: colors[colIdx] }}
              >
                {typeIcon}
              </div>
            </Tooltip>
          )
        }
      }

      avatarObj.loadedTime = room.loadedTime
    }
  }

  return avatarObj.elem
}

export function getAttachmentList(attachments) {
  if (attachments != null && attachments.length > 0) {
    const attachmentList = attachments.map((item) => {
      return attachmentManager.getAttachmentObj(item)
    })
    return attachmentList
  }
  return null
}

export function getReactionKey(reaction) {
  let { key } = ReactionList[reaction.type]
  if (reaction.type >= 7) {
    key = `${key}_${reaction.data}`
  }
  return key
}

export function createReactionInfo(reactions) {
  if (reactions != null && reactions.length > 0) {
    const myEmail = getEnvParam('userEmail')

    const keyList = []
    const membersMap = {}
    const dataMap = {}
    let hasMyCustom = false
    let customItem = null

    reactions.forEach((item) => {
      const key = getReactionKey(item)
      if (membersMap[key] == null) {
        const memberArray = []
        keyList.push(key) // 키 배열 추가(순서 저장).
        membersMap[key] = memberArray
        dataMap[key] = item
      }
      const memberArr = membersMap[key]
      if (item.status > 0) {
        if (item.type === 7 && item.email === myEmail) {
          hasMyCustom = true
          customItem = item
        }
        memberArr.push(item.email)
      }
    })

    const reactionInfo = {
      keyList,
      membersMap,
      dataMap,
      hasMyCustom,
      customItem,
    }

    return reactionInfo
  }
  return null
}

export function updateReactionInfo(reactionInfo, reactions) {
  if (reactionInfo == null) {
    return createReactionInfo(reactions)
  }

  const { keyList, membersMap, dataMap } = reactionInfo

  if (reactions != null && reactions.length > 0) {
    let hasMyCustom = null
    let customItem = null

    const myEmail = getEnvParam('userEmail')

    reactions.forEach((item) => {
      const rData = item
      // 기존에 추가한 reaction을 삭제.
      Object.entries(membersMap).forEach(([, memberArray]) => {
        const pos = memberArray.indexOf(rData.email)
        if (pos >= 0) {
          memberArray.splice(pos, 1)
          if (rData.email === myEmail) {
            hasMyCustom = false
            reactionInfo.customItem = null
          }

          // 길이가 0이 되면 지워야할지 검토해볼 것. (keyList에서도 지워야 함.)
        }
      })

      if (item.status > 0) {
        // 새로 reaction 추가.
        const key = getReactionKey(item)
        if (membersMap[key] == null) {
          const memberArray = []
          keyList.push(key) // 키 배열 추가(순서 저장).
          membersMap[key] = memberArray
          dataMap[key] = item
        }
        const memberArr = membersMap[key]
        const data = dataMap[key]
        if (item.email === myEmail) {
          hasMyCustom = data.type === 7
          customItem = item
        }
        memberArr.push(item.email)
      }

      if (hasMyCustom != null) {
        reactionInfo.hasMyCustom = hasMyCustom
        reactionInfo.customItem = customItem
      }
    })
  }

  return reactionInfo
}

const ReactionList = [
  {
    type: 0,
    key: 'OK',
    label: '👌',
  },
  {
    type: 1,
    key: 'LIKE',
    label: '👍',
  },
  {
    type: 2,
    key: 'HEART',
    label: '❤️',
  },
  {
    type: 3,
    key: 'LAUGH',
    label: '😄',
  },
  {
    type: 4,
    key: 'SURPRISED',
    label: '😲',
  },
  {
    type: 5,
    key: 'SAD',
    label: '😢',
  },
  {
    type: 6,
    key: 'ANGRY',
    label: '😡',
  },
  {
    type: 7,
    key: 'CUSTOM',
    label: customReactionIcon,
  },
]

const ReactionMap = {}

ReactionList.forEach((item) => {
  if (item.key != null) {
    ReactionMap[item.key] = item
  }
})

const getElemForUpdateChatRoom = (system) => {
  let txt = ''
  const mode = 'updateRoom'

  const [item] = system.emails
  const member = dataMgr.globalMemberMap[item] || { email: item }
  const name = getDefaultName(member)

  const nameElemStr = `<span class="nameWrap"><span class="name">${name}</span></span>`

  const elem = []

  if (system.change.display_name != null) {
    const change = system.change.display_name
    txt = i18n.t('chat.message.updateRoomTitle', {
      name: nameElemStr,
      from: `<span class="from">${change.old}</span>`,
      to: `<span class="to">${change.new}</span>`,
    })

    if (txt != null && typeof txt === 'string') {
      txt = parse(txt)
    }

    const changeElem = (
      <div className={classNames('systemMsgWrapper')} key="title">
        <div className={classNames('systemMsg', mode)}>{txt}</div>
      </div>
    )

    elem.push(changeElem)
  }

  if (system.change.access_role != null) {
    if (system.change.access_role === 'public') {
      txt = i18n.t('chat.message.updateRoomToPublic', { name: nameElemStr })
    } else {
      txt = i18n.t('chat.message.updateRoomToPrivate', { name: nameElemStr })
    }

    if (txt != null && typeof txt === 'string') {
      txt = parse(txt)
    }

    const changeElem = (
      <div className={classNames('systemMsgWrapper')} key="role">
        <div className={classNames('systemMsg', mode)}>{txt}</div>
      </div>
    )

    elem.push(changeElem)
  }

  if (system.change.archive != null) {
    if (system.change.archive === true) {
      txt = i18n.t('chat.message.archived', { name: nameElemStr })
    } else {
      txt = i18n.t('chat.message.unarchived', { name: nameElemStr })
    }

    if (txt != null && typeof txt === 'string') {
      txt = parse(txt)
    }

    const changeElem = (
      <div className={classNames('systemMsgWrapper')} key="role">
        <div className={classNames('systemMsg', mode)}>{txt}</div>
      </div>
    )

    elem.push(changeElem)
  }

  return elem
}

const getElemForConvertChatRoom = (system) => {
  let txt = ''
  const mode = 'convertRoom'

  const { who, emails } = system
  const member = dataMgr.globalMemberMap[who] || { email: who }
  const name = getDefaultName(member)

  const nameElemStr = `<span class="nameWrap"><span class="name">${name}</span></span>`

  let memberElemStr = ''

  emails.forEach((email, idx) => {
    const memberItem = dataMgr.globalMemberMap[email] || { email }
    const nameItem = getDefaultName(memberItem)
    if (idx === 0) {
      memberElemStr += `<span class="nameWrap"><span className="name">${nameItem}</span></span>`
    } else {
      memberElemStr += `<span className="comma">,&nbsp;</span><span class="nameWrap"><span className="name">${nameItem}</span></span>`
    }
  })

  const elem = []

  txt = i18n.t('chat.message.convertChatRoom', {
    name: nameElemStr,
    members: memberElemStr,
  })

  if (txt != null && typeof txt === 'string') {
    txt = parse(txt)
  }

  const changeElem = (
    <div className={classNames('systemMsgWrapper')} key="title">
      <div className={classNames('systemMsg', mode)}>{txt}</div>
    </div>
  )

  elem.push(changeElem)

  return elem
}

const getElemForUpdateFile = (system) => {
  let txt = ''
  const mode = 'updateFile'

  const elem = []

  if (system.change.files != null) {
    const { files } = system.change

    if (files.length === 1) {
      txt = i18n.t('chat.message.updateFile', {
        name: files[0].file_name,
      })
    } else {
      txt = i18n.t('chat.message.updateFiles', {
        name: `${files[0].file_name} + ${files.length - 1}`,
      })
    }

    const changeElem = (
      <div className={classNames('systemMsgWrapper')} key="title">
        <div className={classNames('systemMsg', mode)}>{txt}</div>
      </div>
    )

    elem.push(changeElem)
  }

  return elem
}

const getContentFromMsg = (msg) => {
  const { type, content, system } = msg

  let elem = null
  if (type === 'system' && system != null) {
    switch (system.type) {
      case 'add_member':
      case 'enter_member':
      case 'leave_member': {
        elem = system.emails.map((item) => {
          const member = dataMgr.globalMemberMap[item] || { email: item }
          const name = getDefaultName(member)

          const nameElemStr = `<span class="nameWrap"><span class="name">${name}</span></span>`

          let txt = ''
          let mode = null
          if (system.type === 'add_member' || system.type === 'enter_member') {
            mode = 'enter'
            txt = i18n.t('chat.message.addMember', { name: nameElemStr })
          } else if (system.type === 'leave_member') {
            mode = 'leave'
            txt = i18n.t('chat.message.leaveMember', { name: nameElemStr })
          }

          if (txt != null && typeof txt === 'string') {
            txt = parse(txt)
          }

          return (
            <div className={classNames('systemMsgWrapper')} key={item}>
              <div className={classNames('systemMsg', mode)}>{txt}</div>
            </div>
          )
        })
        break
      }
      case 'update_chat_room': {
        elem = getElemForUpdateChatRoom(system)
        break
      }
      case 'convert_chat_room': {
        elem = getElemForConvertChatRoom(system)
        break
      }
      case 'update_file': {
        elem = getElemForUpdateFile(system)
        break
      }
      default:
        elem = content
    }

    return elem
  }

  return content
}

const getMsgObjForEmail = (member, mail) => {
  const from = mail.from != null ? mail.from : mail.sender
  const { address } = from.emailAddress

  return {
    id: mail.internetMessageId,
    msMailId: mail.id,
    type: 'mail',
    senderId: address, // member != null ? member.id : null,
    from: address,
    message: mail.subject,
    timeMS: moment(mail.receivedDateTime).valueOf(),
    time: mail.receivedDateTime,
    origin: mail,
    reactMgr: creactReactObjMgr(),
  }
}

const getMsgObjFromRoomObj = (roomObj) => {
  // eslint-disable-next-line camelcase
  const {
    // last_message_id: messageId,
    last_message: lastMessage,
  } = roomObj.ChatRoom

  const { type } = lastMessage

  if (type === 'comment') {
    return getCommentObjFromMessage(lastMessage)
  }

  return getMsgObjFromMessage(lastMessage)
}

const getMsgObjFromMessage = (message) => {
  // eslint-disable-next-line camelcase
  const {
    message_id: messageId,
    sender,
    status,
    attachments,
    preview_url: previewUrl,
    reactions,
    create_time: time,
    update_time: updateTime,
    comment_count: commentCount,
    reply,
    type,
  } = message

  const utcTime = getUTCTime(time)
  const updateUtcTime = getUTCTime(updateTime)

  const attachmentList = getAttachmentList(attachments)

  const reactionInfo = createReactionInfo(reactions)

  const ret = {
    id: messageId,
    type: type || 'message',
    senderId: sender,
    from: sender,
    attachmentList,
    previewUrl,
    reactionInfo,
    message: getContentFromMsg(message),
    timeMS: moment(utcTime).valueOf(),
    // eslint-disable-next-line camelcase
    time: utcTime,
    updateTime: updateUtcTime,
    status: status != null ? status : 1,
    commentCount,
    replyList: [],
    origin: message,
    reactMgr: creactReactObjMgr(),
  }

  if (reply != null && reply.sender != null) {
    ret.replyList.push(reply)
  }

  // if (type === 'mail') {
  //   ret.msMailId = message. ...;
  // }

  return ret
}

const getMsgObjFromWS = (senderObj, data) => {
  // eslint-disable-next-line camelcase
  const {
    message_id: messageId,
    sender,
    status,
    attachments,
    preview_url: previewUrl,
    reactions,
    create_time: time,
    update_time: updateTime,
    comment_count: commentCount,
    reply,
    type,
  } = data

  const utcTime = getUTCTime(time)
  const updateUtcTime = updateTime != null ? getUTCTime(updateTime) : utcTime

  const attachmentList = getAttachmentList(attachments)

  const reactionInfo = createReactionInfo(reactions)

  const ret = {
    // eslint-disable-next-line camelcase
    id: messageId,
    type: type || 'message',
    senderId: sender,
    from: sender,
    attachmentList,
    previewUrl,
    reactionInfo,
    message: getContentFromMsg(data),
    timeMS: moment(utcTime).valueOf(),
    // eslint-disable-next-line camelcase
    time: utcTime,
    updateTime: updateUtcTime,
    status: status != null ? status : 1,
    commentCount,
    replyList: [],
    origin: data,
    reactMgr: creactReactObjMgr(),
    isWS: true,
  }

  if (reply != null && reply.sender != null) {
    ret.replyList.push(reply)
  }

  // if (type === 'mail') {
  //   ret.msMailId = message. ...;
  // }

  return ret
}

const getCommentObjFromMessage = (message) => {
  // eslint-disable-next-line camelcase
  const {
    comment_id: commentId,
    sender,
    status,
    attachments,
    preview_url: previewUrl,
    reactions,
    create_time: time,
    update_time: updateTime,
    reply,
    type,
  } = message

  const utcTime = getUTCTime(time)
  const updateUtcTime = getUTCTime(updateTime)

  const attachmentList = getAttachmentList(attachments)

  const reactionInfo = createReactionInfo(reactions)

  const ret = {
    id: commentId,
    type: type || 'comment',
    senderId: sender,
    from: sender,
    attachmentList,
    previewUrl,
    reactionInfo,
    message: getContentFromMsg(message),
    timeMS: moment(utcTime).valueOf(),
    // eslint-disable-next-line camelcase
    time: utcTime,
    updateTime: updateUtcTime,
    status: status != null ? status : 1,
    replyList: [],
    origin: message,
    reactMgr: creactReactObjMgr(),
  }

  if (reply != null && reply.sender != null) {
    ret.replyList.push(reply)
  }

  return ret
}

const getCommentObjFromWS = (senderObj, data) => {
  // eslint-disable-next-line camelcase
  const {
    comment_id: commentId,
    sender,
    status,
    attachments,
    preview_url: previewUrl,
    reactions,
    create_time: time,
    update_time: updateTime,
    reply,
    type,
  } = data

  const utcTime = getUTCTime(time)
  const updateUtcTime = updateTime != null ? getUTCTime(updateTime) : utcTime

  const attachmentList = getAttachmentList(attachments)

  const reactionInfo = createReactionInfo(reactions)

  const ret = {
    // eslint-disable-next-line camelcase
    id: commentId,
    type: type || 'comment',
    senderId: sender,
    from: sender,
    attachmentList,
    previewUrl,
    reactionInfo,
    message: getContentFromMsg(data),
    timeMS: moment(utcTime).valueOf(),
    // eslint-disable-next-line camelcase
    time: utcTime,
    updateTime: updateUtcTime,
    status: status != null ? status : 1,
    replyList: [],
    origin: data,
    reactMgr: creactReactObjMgr(),
    isWS: true,
  }

  if (reply != null && reply.sender != null) {
    ret.replyList.push(reply)
  }

  return ret
}

const createDateMsgFromMsg = (message) => {
  const date = timeFormat(message.time, 'YYYY-MM-DD')
  const newTime = `${date}T00:00:00.000000`
  const id = `t_${newTime}`
  return {
    id,
    type: 'date',
    senderId: '',
    from: '',
    message: date,
    timeMS: moment(newTime).valueOf(),
    time: newTime,
    reactMgr: creactReactObjMgr(),
  }
}

export function processImageBlob(blob, filename, mimetype, onloaded) {
  if (blob.type === 'application/json') {
    blob.text().then((text) => {
      const resProps = JSON.parse(text)
      if (resProps.code === 404) {
        // thumb 파일이 없음.
        console.log('thumbfile is not exist.')
      }

      if (onloaded != null) {
        onloaded(null)
      }
    })
  } else if (blob.type === 'text/xml') {
    const lcFileName = filename.toLowerCase()
    if (lcFileName.endsWith('.svg') === true) {
      // blob.text().then((text) => {
      const newBlob = new Blob([blob], { type: 'image/svg+xml' })
      const newThumbFile = new File([newBlob], `${filename}_thumb`, {
        type: 'image/svg+xml',
      })

      if (onloaded != null) {
        onloaded({
          blob: newBlob,
          thumbFile: newThumbFile,
        })
      }
    } else if (lcFileName.endsWith('.heic') === true || lcFileName.endsWith('.heif') === true) {
      const newBlob = new Blob([blob], { type: mimetype || 'image/heic' })
      const newThumbFile = new File([newBlob], `${filename}_thumb`, {
        type: mimetype || 'image/heic',
      })

      if (onloaded != null) {
        onloaded({
          blob: newBlob,
          thumbFile: newThumbFile,
        })
      }
    } else if (mimetype != null) {
      const newBlob = new Blob([blob], { type: mimetype })
      const newThumbFile = new File([newBlob], `${filename}_thumb`, { type: mimetype })
      if (onloaded != null) {
        onloaded({
          blob: newBlob,
          thumbFile: newThumbFile,
        })
      }
    }
  } else {
    const option = {}
    if (mimetype != null) {
      option.type = mimetype
    }
    const newThumbFile = new File([blob], `${filename}_thumb`, option)
    if (onloaded != null) {
      onloaded({
        blob,
        thumbFile: newThumbFile,
      })
    }
  }
}

export function getRoomMembers(roomObj) {
  const roomMembers = (roomObj.roomMemberList != null) ?
    roomObj.roomMemberList : roomObj.ChatRoom.members;

  return roomMembers;
}

const cUtil = {
  ReactionList,
  ReactionMap,
  getInitialAvatar,
  getMsgTextJson,
  getContentFromMsg,
  getMsgObjForEmail,
  getMsgObjFromRoomObj,
  getMsgObjFromMessage,
  getMsgObjFromWS,
  getCommentObjFromMessage,
  getCommentObjFromWS,
  createDateMsgFromMsg,
  processImageBlob,
  getRoomMembers
}

export default cUtil
