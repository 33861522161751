import React from 'react'

import classNames from 'classnames'
import { Tooltip } from 'antd'
import i18n from 'i18n'

import dataMgr from 'pages/chat/component/chatView/data/dataManager'
import { getMemberInfo } from 'pages/chat/component/chatView/data/cUtil'

import './style.module.scss'

class MemberDetail extends React.Component {
  dataMgr = dataMgr

  componentDidMount() {
    // const { info } = this.props;
    // console.log(' mention info : ', info)
  }

  // componentDidUpdate(prevProps) {
  // }

  componentWillUnmount() {}

  render() {
    const { extraElem, email, member: member0 } = this.props

    const member = member0 || this.dataMgr.globalMemberMap[email]

    const memberInfo = getMemberInfo(member, email, null, false)

    const imgSrc = memberInfo.avatarSrc
    const displayName = memberInfo.name

    const memberTypeMap = {
      manager: i18n.t('chat.role.manager'),
      owner: i18n.t('chat.role.owner'),
      member: i18n.t('chat.role.member'),
      admin: i18n.t('chat.role.admin'),
    }

    return (
      <div className="member-container">
        <div className="avatar">
          <img src={imgSrc} alt={displayName} />
        </div>
        <div className="info">
          <Tooltip title={displayName}>
            <div className="name">{displayName}</div>
          </Tooltip>
          <div className="role">{memberTypeMap[member.type]}</div>
          <div className="email">{email}</div>
          <div className={classNames('extra-container')}>{extraElem}</div>
        </div>
      </div>
    )
  }
}

export default MemberDetail
