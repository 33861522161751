// import { message } from 'antd'

import Ngql from 'gql/ninegql'

const workspaceNodeField = {
  id: true,
  iconUrl: true,
  name: true,
}

const queryMyAccount = Ngql.Query('userInfo', {
  me: Ngql.Node({
    id: true,
    email: true,
    displayName: true,
    avatarUrl: true,
    // role: true,
  }),
  // hello: true,
  // serverType: true,
})

const workspaceInfoField = {
  workspaceEmail: true,
  workspaceName: true,
  iconUrl: true,
  role: true,
  enableChat: true,
}

const queryGetWorkspaceInfo = Ngql.Query('getWorkspaceInfo', {
  getWorkspaceInfo: Ngql.Node(workspaceInfoField, null, false, {
    workspaceId: Ngql.Var('workspaceId', 'Int!'),
  }),
  // hello: true,
})

const workspaceNode = Ngql.Node(workspaceNodeField)

const queryWorkspaceList = Ngql.Query('workspaceList', {
  allWorkspaces: Ngql.NodeConnection(workspaceNode, {
    sort: Ngql.Var('sort', '[WorkspaceNodeSortEnum]'),
    pageNum: Ngql.Var('pageNum', 'Int'),
    pageSize: Ngql.Var('pageSize', 'Int'),
    filters: Ngql.Var('filters', 'WorkspaceFilter'),
  }),
})

const deviceInfoNodeField = {
  deviceUuid: true,
  fcmToken: true,
  type: true,
  name: true,
  osVersion: true,
  appVersion: true,
}

const deviceInfoNode = Ngql.Node(deviceInfoNodeField)

const mutationUpdateDevice = Ngql.Mutation('updateDevice', {
  updateDevice: Ngql.Node(
    {
      device: deviceInfoNode,
      active: true,
      ok: true,
      errors: true,
    },
    null,
    false,
    {
      deviceUuid: Ngql.Var('deviceUuid', 'String!'),
      workspaceId: Ngql.Var('workspaceId', 'Int'),
      deviceData: Ngql.Var('deviceData', 'DeviceInput'),
    },
  ),
})

const queries = {
  queryMyAccount,
  queryGetWorkspaceInfo,
  queryWorkspaceList,
  mutationUpdateDevice,
}

export default queries
