import React from 'react'
// import FavPages from './FavPages'
// import Search from './Search'
// import IssuesHistory from './IssuesHistory'
// import ProjectManagement from './ProjectManagement'
// import LanguageSwitcher from './LanguageSwitcher'
// import Actions from './Actions'
import WorkspaceMenu from './WorkspaceMenu'
import UserMenu from './UserMenu'
import styles from './style.module.scss'

const TopBar = () => {
  return (
    <div className={styles.topbar}>
      <div className="mr-auto" id="search-container" />
      {/*
      <div className="mr-4">
        <FavPages />
      </div>
      <div className="mr-auto">
        <Search />
      </div>
      <div className="mr-4 d-none d-md-block">
        <IssuesHistory />
      </div>
      <div className="mb-0 mr-auto d-xl-block d-none">
        <ProjectManagement />
      </div>
      <div className="mr-4 d-sm-block">
        <LanguageSwitcher />
      </div>
      <div className="mr-4 d-none d-sm-block">
        <Actions />
      </div>
      */}
      <div className="mr-4">
        <React.Suspense fallback={<div>Loading...</div>}>
          <WorkspaceMenu />
        </React.Suspense>
      </div>
      <div className="">
        <React.Suspense fallback={<div>Loading...</div>}>
          <UserMenu />
        </React.Suspense>
      </div>
    </div>
  )
}

export default TopBar
