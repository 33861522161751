/* eslint-disable global-require */
import { getEnvParam } from 'env'

const configMap = {}

export default function appTypeConfigFunc() {
  let appTypeInfo = {};
  try {
    appTypeInfo = JSON.parse(getEnvParam('appType') || '{}');
  } catch {
    appTypeInfo = {};
  }

  let appType = appTypeInfo.role;
  if (appTypeInfo.enableChat !== true) {
    appType = 'no-chat';
  }

  if (configMap[appType] == null) {
    const config = {}

    // serverType.
    // - REWORK-ONLINE (ex: api.rework.so) - master admin.
    // - REWORK-ENTERPRISE-ONLINE (ex: api.rework.so) - enterprise admin.
    // - REWORK-ENTERPRISE-PORTAL (ex: api.appurityconnect.com) - sub master admin.
    // - REWORK-ENTERPRISE (ex: appdev.kolon.com) - admin

    switch (appType) {
      case 'owner':
      case 'admin':
        config.menu = require('./admin/menu').default
        config.router = require('./admin/router').default
        config.config = require('./admin/config').default
        break
      case 'no-chat':
        config.menu = require('./admin-no-chat/menu').default
        config.router = require('./admin-no-chat/router').default
        config.config = require('./admin-no-chat/config').default
        break
      case 'REWORK-ONLINE':
        config.menu = require('./rework-online/menu').default
        config.router = require('./rework-online/router').default
        config.config = require('./rework-online/config').default
        break
      case 'REWORK-ENTERPRISE-ONLINE':
        config.menu = require('./rework-enterprise-online/menu').default
        config.router = require('./rework-enterprise-online/router').default
        config.config = require('./rework-enterprise-online/config').default
        break
      default:
        config.menu = () => []
        config.router = () => []
        config.config = {
          defaultPage: '/',
        }
    }

    configMap[appType] = config
  }

  return configMap[appType]
}
