import GitInfo from 'react-git-info/macro'
import packageJson from '../package.json';

const gitInfo = GitInfo()

let confProp
if (process.env.REACT_APP_TARGET === 'production') {
  confProp = {
    DEBUG: false,
    SERVER: {
      restLandigURL: 'https://api.enterprise.rework.so/v1',
      // URL: 'https://api.enterprise.rework.so/__admin/graphql',  <--(@정호:CONSOLE에서 ENTERPRISE호출시 __admin아님)
      URL: 'https://api.enterprise.rework.so/graphql',
      restURL: 'https://chat.rework.so',
      wsAddress: 'chat-websocket.rework.so',
      wsURL: null,
    },
  }
} else if (process.env.REACT_APP_TARGET === 'test') {
  confProp = {
    DEBUG: false,
    SERVER: {
      restLandigURL: 'https://dev.api.enterprise.rework.so/v1',
      URL: 'https://dev.api.enterprise.rework.so/graphql',
      // restURL: 'http://localhost:4030',
      restURL: 'https://dev.chat.rework.so',
      wsAddress: 'dev.chat.rework.so',
      wsURL: null,
    },
  }
} else if (process.env.REACT_APP_TARGET === 'test1') {
  confProp = {
    DEBUG: false,
    SERVER: {
      restLandigURL: 'https://dev.api.enterprise.rework.so/v1',
      // restURL: 'http://localhost:4030',
      restURL: 'http://192.168.50.100:8000',
      wsAddress: '192.168.50.100:8000',
      URL: null,
      wsURL: null,
    },
  }
} else if (process.env.REACT_APP_TARGET === 'log') {
  confProp = {
    DEBUG: false,
    SERVER: {
      restURL: 'http://192.168.50.100:8001/v1',
      URL: 'http://192.168.50.100:8001/__admin/graphql',
      wsURL: 'ws://192.168.50.100:8002/graphql/',
    },
  }
} else if (process.env.REACT_APP_TARGET === 'log2') {
  confProp = {
    DEBUG: false,
    SERVER: {
      restURL: 'http://192.168.50.102:8001/v1',
      URL: 'http://192.168.50.102:8001/__admin/graphql',
      wsURL: 'ws://192.168.50.102:8002/graphql/',
    },
  }
} else {
  confProp = {
    DEBUG: true,
    SERVER: {
      restURL: 'http://localhost:8001/v1',
      URL: 'http://localhost:8001/__admin/graphql',
      wsURL: 'ws://localhost:8002/graphql/',
    },
  }
}

confProp.defaultLocale = 'en-US' // 'ko-KR'

if (window.globalConfig != null) {
  if (window.globalConfig.restURL != null) {
    confProp.SERVER.restURL = window.globalConfig.restURL
  }
  if (window.globalConfig.URL != null) {
    confProp.SERVER.URL = window.globalConfig.URL
  }
  if (window.globalConfig.LOCALE != null) {
    confProp.defaultLocale = window.globalConfig.LOCALE
  }
}

confProp.gitInfo = gitInfo
confProp.packageInfo = packageJson

const conf = confProp

export default conf
