import React from 'react'
import { Button, Dropdown, List, Input } from 'antd'
// import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons'
import PerfectScrollbar from 'react-perfect-scrollbar-z'

import classNames from 'classnames'
import i18n from 'i18n'

import { getHighlightMemberElem } from 'pages/chat/component/MemberView/memberUtil'
import { getRoomMembers } from 'pages/chat/component/chatView/data/cUtil'
import { confirmDeleteMembers, confirmChangeRole } from 'pages/chat/component/chatUI'
import {
  getRoom,
} from 'pages/chat/component/chatView/data/adminRoomUtil'

import iconSearchSVG from 'assets/images/icon-search.svg'
import menuHSVG from 'assets/images/button-menu-h.svg'

import dataManager from '../chatView/data/dataManager'

import styles from '../style.module.scss'

class RoomMemberListDef extends React.Component {
  state = {
    searchResults: [],
    searchStr: null,
  }

  searchTimer = null

  selectedList = []

  selectedMap = {}

  tempMemberMap = {}

  scrollRef = React.createRef()

  resultRef = React.createRef()

  componentDidMount() {
    const { roomObj } = this.props

    setTimeout(() => {
      this.setRoomObj(roomObj, '')
    }, 10)
  }

  componentDidUpdate(prevProps) {
    const { roomObj } = this.props

    if (prevProps.roomObj !== roomObj) {
      this.setRoomObj(roomObj, this.lastSearchTxt)
    }
  }

  componentWillUnmount() {}

  onToggleMember = () => {}

  innerSearchMember = (str, onGetList) => {
    const txt = str || ''

    const roomMembers = getRoomMembers(this.roomObj);

    let resultMembers = []

    if (txt.trim().length === 0) {
      resultMembers = roomMembers.filter((member) => {
        return member.status === 1
      })
    } else {
      resultMembers = roomMembers.filter((member) => {
        if (member.status !== 1) {
          return false
        }
        if (member.email.indexOf(txt) >= 0) {
          return true
        }
        if (member.display_name != null && member.display_name.indexOf(txt) >= 0) {
          return true
        }

        return false
      })
    }

    if (onGetList != null) {
      onGetList(resultMembers)
    }
  }

  onSearch = (str) => {
    const roomId = this.roomObj.ChatRoom.id
    this.lastSearchTxt = str

    dataManager.getRoomObj(
      roomId,
      (newRoomObj) => {
        this.setRoomObj(newRoomObj, str)
      },
      false,
      false,
      true,
    )
  }

  setRoomObj = (roomObj, str) => {
    this.roomObj = roomObj

    this.innerSearchMember(str, (list) => {
      console.log(' - innerSearchMember : ', list)

      const resultList = list

      this.setState({
        searchResults: resultList,
        searchStr: str,
      })

      if (this.resultRef != null && this.resultRef.current != null) {
        this.resultRef.current.element.scrollTop = 0
        this.resultRef.current.update()
      }
    })
  }

  reload = () => {
    getRoom(this.roomObj.ChatRoom.id).then((res) => {
      console.log(' room info - ', res);
      if (res.data.code === 200) {
        const newRoomObj = res.data.data;
        this.setRoomObj(newRoomObj, this.lastSearchTxt);
      }
    })
  }

  onMenuClick = (key, member) => {
    const { onChange } = this.props;

    console.log(' onMenuClick - ', key, member);
    switch (key) {
      case 'removeMember':
        confirmDeleteMembers(this.roomObj, [member], () => {
          this.reload();
          if (onChange != null) {
            onChange({
              members: {
                remove: true,
              },
            });
          }
        });
        break;
      case 'changeRole':
        confirmChangeRole(this.roomObj, member, () => {
          this.reload();
          if (onChange != null) {
            onChange({
              members: {
                change: true,
              },
            });
          }
        });
        break;
      default:
    }
  }

  renderItem = (member) => {
    const { searchStr } = this.state

    const elem = getHighlightMemberElem(member.email, member, styles, searchStr)

    const menu = {
      items: [
        {
          label: i18n.t('chat.menu.removeMember'),
          key: 'removeMember',
          disabled: false,
        },
        {
          label: i18n.t('chat.menu.changeRole'),
          key: 'changeRole',
        },
      ],
      mode: 'vertical',
      onClick: (e) => {
        console.log('onClick - ', e);
        const { key, domEvent } = e;
        this.onMenuClick(key, member)
        if (domEvent != null) {
          domEvent.preventDefault();
          domEvent.stopPropagation();
        }
      },
    }

    const dropdownAttrs = {
      menu,
      overlayClassName: classNames('floatingSubMenu', 'menuStyle1'),
      trigger: ['click'],
    }

    const dropdownElem = (
      <Dropdown {...dropdownAttrs}>
        <Button type="text" icon={<img src={menuHSVG} alt="menu" />} htmlType="button" />
      </Dropdown>
    )

    const memberTypeMap = {
      manager: i18n.t('chat.role.manager'),
      owner: i18n.t('chat.role.owner'),
      member: i18n.t('chat.role.member'),
      admin: i18n.t('chat.role.admin'),
    }

    const actions = [
      (
        <span className={classNames('memberType')}>{memberTypeMap[member.type]}</span>
      ),
      (
        <React.Fragment>{dropdownElem}</React.Fragment>
      )
    ];

    return (
      <List.Item
        className="edit-tag member"
        actions={actions}
      >
        {elem}
      </List.Item>
    )
  }

  render = () => {
    const { searchResults } = this.state

    return (
      <div className={classNames(styles.selectMember, styles.type2)}>
        <Input.Search
          placeholder="Search"
          onSearch={this.onSearch}
          enterButton={<img src={iconSearchSVG} alt="search" />}
        />
        <div className={styles.subTitle}>{i18n.t('chat.setting.chooseMember')}</div>
        <PerfectScrollbar
          refScroll={this.resultRef}
          className={classNames(styles.membersWrap, 'border')}
        >
          <List
            dataSource={searchResults}
            renderItem={this.renderItem}
          />
        </PerfectScrollbar>
      </div>
    )
  }
}

export default RoomMemberListDef
