/* eslint-disable no-underscore-dangle */
import React from 'react'
import {
  Checkbox,
  Button,
  Modal,
  Radio,
  Upload,
  Form,
  Select,
  Input,
  InputNumber,
  DatePicker,
  TimePicker,
  Descriptions,
  Table,
  List,
} from 'antd'
import moment from 'moment'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'

import JsonEditor from 'components/JsonEditor'
import SchemaJsonEditor from 'components/SchemaJsonEditor'
import JsonEditorChooser from 'components/JsonEditorChooser'
import DeviceSizeChecker from 'components/DeviceSizeChecker'

import { getNgqlConfig } from 'env'
import {
  timeFormat,
  timeFormatUTC,
  timeFormatUTCDefault,
  timeFormatDefault,
  removeTag,
} from 'utils'

const ngqlConfig = getNgqlConfig() || {}

const { Option } = Select

function str(val) {
  if (val == null || val.length === 0) {
    return '-'
  }
  return val
}

function extend(dest, obj) {
  const target = dest || {}
  if (obj != null) {
    Object.entries(obj).forEach(([key, value]) => {
      target[key] = value
    })
  }

  return target
}

function getAttrs(info, reactObj, value, values) {
  let attrs = {}
  let inputAttrs = {}

  if (info.options && info.options.attrs) {
    attrs = extend(attrs, info.options.attrs)
  }
  if (info.config) {
    attrs = extend(attrs, info.config)
  }
  if (info.origin) {
    if (info.origin.default != null) {
      attrs = extend(attrs, { initialValue: info.origin.default })
    }
    if (info.origin.mode === 'read' || info.origin.mode === 'readonly') {
      inputAttrs.disabled = true
    }
  }

  if (info.initValue != null) {
    attrs.initialValue = info.initValue
  }

  const cname = `nine-${info.name}`
  if (info.origin && info.origin.className != null) {
    let { className } = info.origin
    const cnames = className.split(' ')
    if (className && cnames.indexOf(cname) === -1) {
      className += ` ${cname}`
    }
    attrs.className = className
  } else {
    attrs.className = cname
  }

  if (info.origin.inputAttrs) {
    const originInputAttr =
      typeof info.origin.inputAttrs === 'function'
        ? info.origin.inputAttrs(info, reactObj, value, values)
        : info.origin.inputAttrs
    inputAttrs = extend(inputAttrs, originInputAttr)
  }

  if (attrs.rules != null) {
    // antd에서는 rules에 message 등 정해진 key이외의 값이 존재하면, 제대로 validation 체크가 되지 않으므로 messageKey를 삭제.
    const realRules = []
    attrs.rules.forEach((item) => {
      const realRule = {
        ...item,
      }
      delete realRule.messageKey
      realRules.push(realRule)
    })
    attrs.rules = realRules
  }

  return {
    attrs,
    inputAttrs,
  }
}

// export function createCSSLabel(columns) {
//   const vars = {}

//   columns.forEach((column, idx) => {
//     let title = null
//     if (column.mobileTitle != null) {
//       const tmp = removeTag(column.mobileTitle.trim())
//       title = tmp.length > 0 ? `'${tmp} : '` : ''
//     } else if (typeof column.title === 'string') {
//       const tmp = removeTag(column.title.trim())
//       title = tmp.length > 0 ? `'${tmp} : '` : ''
//     }
//     if (title != null) {
//       vars[`--label${idx + 1}`] = title
//     }
//   })

//   return vars
// }

function createInput(info, reactObj, value, values) {
  const { attrs, inputAttrs } = getAttrs(info, reactObj, value, values)

  let children = ''
  if (info.type === 'number') {
    if (attrs.initialValue != null && typeof attrs.initialValue === 'string') {
      attrs.initialValue = window.parseInt(attrs.initialValue)
    }
    children = <InputNumber placeholder={info.desc} {...inputAttrs} />
  } else if (info.type === 'checkbox') {
    children = <Checkbox {...inputAttrs} />
  } else if (info.type === 'url') {
    children = <Input type="url" placeholder={info.desc} {...inputAttrs} />
  } else if (info.type === 'datetime' || info.type === 'datetime-utc') {
    children = <Input type="datetime-local" placeholder={info.desc} {...inputAttrs} />
  } else if (info.type === 'datetime-ufw' || info.type === 'datetime-ufw-utc') {
    children = <DatePicker showTime placeholder={info.desc} {...inputAttrs} />
  } else if (info.type === 'date' || info.type === 'date-utc') {
    children = <Input type="date" placeholder={info.desc} {...inputAttrs} />
  } else if (info.type === 'date-ufw' || info.type === 'date-ufw-utc') {
    children = <DatePicker placeholder={info.desc} {...inputAttrs} />
  } else if (info.type === 'time' || info.type === 'time-utc') {
    children = <Input type="time" placeholder={info.desc} {...inputAttrs} />
  } else if (info.type === 'time-ufw' || info.type === 'time-ufw-utc') {
    children = <TimePicker placeholder={info.desc} {...inputAttrs} />
  } else {
    children = <Input placeholder={info.desc} {...inputAttrs} />
  }

  // const afterText = info.afterText ? info.afterText : ''

  return (
    <Form.Item label={info.editLabel} name={info.name} key={info.name} {...attrs}>
      {children}
    </Form.Item>
  )
}

function createTextarea(info, reactObj, value, values) {
  const { attrs, inputAttrs } = getAttrs(info, reactObj, value, values)

  return (
    <Form.Item label={info.editLabel} name={info.name} key={info.name} {...attrs}>
      <Input.TextArea placeholder={info.desc} {...inputAttrs} />
    </Form.Item>
  )
}

function createJSONEditor(info, reactObj, value, values) {
  const { attrs, inputAttrs } = getAttrs(info, reactObj, value, values)

  let lastError = {
    hasError: false,
  }

  const options = {
    mode: 'code',
    // indent: 2,
    search: false,
  }

  const newRules = []
  let validator = null
  let validateRule = null

  if (attrs.rules != null) {
    attrs.rules.forEach((item) => {
      if (typeof item === 'function') {
        validator = item
      } else if (item != null && typeof item.validator === 'function') {
        validator = item
      } else if (item.validate != null) {
        validateRule = item
      } else {
        newRules.push(item)
      }
    })
  }

  if (validateRule == null && validator != null) {
    newRules.push(validator)
  } else {
    const validatorFunc =
      validator != null && typeof validator.validator === 'function'
        ? validator.validator
        : validator
    const message = validateRule != null ? validateRule.message : 'JSON Syntex error.'

    const newValidator = {
      validator: async (/* _, names */) => {
        if (lastError.hasError !== true) {
          if (validatorFunc != null) {
            validatorFunc()
          }
          return Promise.resolve()
        }
        return Promise.reject(new Error(message))
      },
    }

    newRules.push(newValidator)
  }

  attrs.rules = newRules

  const onChange = (e) => {
    console.log('onChange ~!!', e)
  }

  const onError = (e) => {
    console.log('onError ~!!', e)
    lastError = e
  }

  // attrs.getValueProps = (param1, param2) => {
  //   // eslint-disable-next-line prefer-rest-params
  //   console.log( ' getValueProp - ', arguments, param1, param2);
  // }

  return (
    <Form.Item
      label={info.editLabel}
      name={info.name}
      key={info.name}
      valuePropName="value"
      {...attrs}
    >
      <JsonEditor
        style={inputAttrs.style}
        onError={onError}
        onChange={onChange}
        options={options}
        {...inputAttrs}
      />
    </Form.Item>
  )
}

function createSchemaJSONEditor(info, reactObj, value, values) {
  const { attrs, inputAttrs } = getAttrs(info, reactObj, value, values)

  let lastError = {
    hasError: false,
  }

  const newRules = []
  let validator = null
  let validateRule = null

  if (attrs.rules != null) {
    attrs.rules.forEach((item) => {
      if (typeof item === 'function') {
        validator = item
      } else if (item != null && typeof item.validator === 'function') {
        validator = item
      } else if (item.validate != null) {
        validateRule = item
      } else {
        newRules.push(item)
      }
    })
  }

  if (validateRule == null && validator != null) {
    newRules.push(validator)
  } else {
    const validatorFunc =
      validator != null && typeof validator.validator === 'function'
        ? validator.validator
        : validator
    const message = validateRule != null ? validateRule.message : 'JSON Syntex error.'

    const newValidator = {
      validator: async (/* _, names */) => {
        if (lastError.hasError !== true) {
          if (validatorFunc != null) {
            validatorFunc()
          }
          return Promise.resolve()
        }
        return Promise.reject(new Error(message))
      },
    }

    newRules.push(newValidator)
  }

  attrs.rules = newRules

  const onChange = (e) => {
    console.log('onChange ~!!', e)
  }

  const onError = (e) => {
    console.log('onError ~!!', e)
    lastError = e
  }

  console.log(' --- ', inputAttrs.options)

  return (
    <Form.Item
      label={info.editLabel}
      name={info.name}
      key={info.name}
      valuePropName="value"
      {...attrs}
    >
      <SchemaJsonEditor
        style={inputAttrs.style}
        onError={onError}
        onChange={onChange}
        {...inputAttrs}
      />
    </Form.Item>
  )
}

function createJSONEditorChooser(info, reactObj, value, values) {
  const { attrs, inputAttrs } = getAttrs(info, reactObj, value, values)

  let lastError = {
    hasError: false,
  }

  const options = {
    mode: 'code',
    // indent: 2,
    search: false,
  }

  const newRules = []
  let validator = null
  let validateRule = null

  if (attrs.rules != null) {
    attrs.rules.forEach((item) => {
      if (typeof item === 'function') {
        validator = item
      } else if (item != null && typeof item.validator === 'function') {
        validator = item
      } else if (item.validate != null) {
        validateRule = item
      } else {
        newRules.push(item)
      }
    })
  }

  if (validateRule == null && validator != null) {
    newRules.push(validator)
  } else {
    const validatorFunc =
      validator != null && typeof validator.validator === 'function'
        ? validator.validator
        : validator
    const message = validateRule != null ? validateRule.message : 'JSON Syntex error.'

    const newValidator = {
      validator: async (/* _, names */) => {
        if (lastError.hasError !== true) {
          if (validatorFunc != null) {
            validatorFunc()
          }
          return Promise.resolve()
        }
        return Promise.reject(new Error(message))
      },
    }

    newRules.push(newValidator)
  }

  attrs.rules = newRules

  const onChange = (e) => {
    console.log('onChange ~!!', e)
  }

  const onError = (e) => {
    console.log('onError ~!!', e)
    lastError = e
  }

  return (
    <Form.Item
      label={info.editLabel}
      name={info.name}
      key={info.name}
      valuePropName="value"
      {...attrs}
    >
      <JsonEditorChooser
        style={inputAttrs.style}
        onError={onError}
        onChange={onChange}
        options={options}
        inputAttrs={inputAttrs}
      />
    </Form.Item>
  )
}

function createHidden(info, reactObj, value, values) {
  const { attrs, inputAttrs } = getAttrs(info, reactObj, value, values)

  return (
    <Form.Item
      label={info.editLabel}
      name={info.name}
      key={info.name}
      validateStatus="success"
      {...attrs}
      noStyle
    >
      <Input type="hidden" {...inputAttrs} />
    </Form.Item>
  )
}

function createCheckbox(info, reactObj, value, values) {
  const { attrs, inputAttrs } = getAttrs(info, reactObj, value, values)
  const children = <Checkbox {...inputAttrs} />

  return (
    <Form.Item
      label={info.editLabel}
      name={info.name}
      key={info.name}
      valuePropName="checked"
      {...attrs}
    >
      {children}
    </Form.Item>
  )
}

function createSelect(info, reactObj, val, values, isNumberType) {
  // const onChange = info.options ? info.options.onChange : null
  const i18nT = info.options && info.options.t ? info.options.t : null

  const { attrs, inputAttrs } = getAttrs(info, reactObj, val, values)

  const iAttrs = inputAttrs || {}

  let options = ''
  if (i18nT && info.origin.valueKeys) {
    options = Object.entries(info.origin.valueKeys).map(([key, value]) => {
      const oVal = isNumberType === true ? parseInt(key, 10) : key
      return (
        <Option value={oVal} key={key}>
          {i18nT(value)}
        </Option>
      )
    })
  } else {
    options = Object.entries(info.origin.values).map(([key, value]) => {
      const oVal = isNumberType === true ? parseInt(key, 10) : key
      return (
        <Option value={oVal} key={key}>
          {value}
        </Option>
      )
    })
  }

  return (
    <Form.Item label={info.editLabel} name={info.name} key={info.name} {...attrs}>
      <Select placeholder={info.desc} {...iAttrs}>
        {options}
      </Select>
    </Form.Item>
  )
}

function createRadio(info, reactObj, val, values) {
  const i18nT = info.options && info.options.t ? info.options.t : null

  const { attrs, inputAttrs } = getAttrs(info, reactObj, val, values)

  let options = ''
  if (i18nT && info.origin.valueKeys) {
    options = Object.entries(info.origin.valueKeys).map(([key, value]) => (
      <Radio value={key} key={key}>
        {i18nT(value)}
      </Radio>
    ))
  } else {
    options = Object.entries(info.origin.values).map(([key, value]) => (
      <Radio value={key} key={key}>
        {value}
      </Radio>
    ))
  }

  return (
    <Form.Item label={info.editLabel} name={info.name} key={info.name} {...attrs}>
      <Radio.Group name={info.name} {...inputAttrs}>
        {options}
      </Radio.Group>
    </Form.Item>
  )
}

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

function createImageUpload(info, reactObj, value, values) {
  const { attrs } = getAttrs(info, reactObj, value, values)

  console.log(attrs)

  const onCheckfile = info.origin ? info.origin.onCheckfile : null

  const { options, origin } = info

  const alt = options.t && origin.altKey ? options.t(origin.altKey) : origin.alt
  const uploadBtn =
    options.t && origin.uploadBtnKey ? options.t(origin.uploadBtnKey) : origin.uploadBtn

  const beforeUpload = (file) => {
    if (onCheckfile != null) {
      const result = onCheckfile(file)
      if (result === true) {
        file.status = 'fake-done'
      }
    }
    return false
  }

  if (reactObj._ngqlObj == null) {
    reactObj._ngqlObj = {}
  }

  if (reactObj._ngqlObj[info.name] == null) {
    reactObj._ngqlObj[info.name] = {
      imageUrl: null,
      loading: false,
    }
  }

  const handleChange = (evt) => {
    if (evt.file.status === 'uploading') {
      info.state = {
        imageUrl: null,
        loading: true,
      }
      if (reactObj != null) {
        reactObj.forceUpdate()
      }
      return
    }
    // if (evt.file.status === 'done') {
    // Get this url from response in real world.
    getBase64(evt.file, (imageUrl) => {
      reactObj._ngqlObj[info.name] = {
        imageUrl,
        loading: false,
      }
      if (reactObj != null) {
        reactObj.forceUpdate()
      }
    })
    // }
    // else if (evt.file.status === 'fake-done') {
    //   // Get this url from response in real world.
    //   getBase64(evt.file.originFileObj, imageUrl => {
    //     info.state = {
    //       imageUrl,
    //       loading: false,
    //     }
    //     if (reactObj != null) {
    //       reactObj.forceUpdate()
    //     }
    //   })
    // }
  }

  const uploadButton = (
    <div>
      {reactObj._ngqlObj[info.name].loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{uploadBtn}</div>
    </div>
  )

  const children = (
    <Upload
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={(file) => beforeUpload(file)}
      onChange={handleChange}
    >
      {reactObj._ngqlObj[info.name].imageUrl ? (
        <img src={reactObj._ngqlObj[info.name].imageUrl} alt={alt} style={{ width: '100%' }} />
      ) : (
        uploadButton
      )}
    </Upload>
  )

  return (
    <Form.Item
      label={info.editLabel}
      name={info.name}
      key={info.name}
      valuePropName="file"
      {...attrs}
    >
      {children}
    </Form.Item>
  )
}

function createEditElem(compInfo, reactObj, value, values) {
  if (compInfo.origin.mode === 'readonly') {
    // return <React.Fragment>{attrs.initialValue}</React.Fragment>
    return ''
  }

  let comp = null
  switch (compInfo.type) {
    case 'custom':
      if (compInfo.origin.createEditElem != null) {
        comp = compInfo.origin.createEditElem(compInfo, reactObj, value, NineGQLUI, values)
      } else {
        console.log('not fount - origin.createEditElem')
      }
      break
    case 'select':
      comp = createSelect(compInfo, reactObj, value, values, false)
      break
    case 'select-number':
      comp = createSelect(compInfo, reactObj, value, values, true)
      break
    case 'radio':
      comp = createRadio(compInfo, reactObj, value, values)
      break
    case 'checkbox':
      comp = createCheckbox(compInfo, reactObj, value, values)
      break
    case 'hidden':
      comp = createHidden(compInfo, reactObj, value, values)
      break
    case 'textarea':
    case 'formattedtext':
      comp = createTextarea(compInfo, reactObj, value, values)
      break
    case 'long-json':
    case 'json':
      comp = createJSONEditor(compInfo, reactObj, value, values)
      break
    case 'schema-json':
      comp = createSchemaJSONEditor(compInfo, reactObj, value, values)
      break
    case 'json2':
      comp = createJSONEditorChooser(compInfo, reactObj, value, values)
      break
    case 'image':
      comp = createImageUpload(compInfo, reactObj, value, values)
      break
    case 'text':
    case 'number':
    case 'url':
    case 'email':
    case 'datetime':
    default:
      comp = createInput(compInfo, reactObj, value, values)
  }

  return comp
}

function createEditContainer(childrenObj, options, uiProps, uiAttrs, formRef, btnsUI) {
  let attrs = {}
  if (options.layout != null) {
    attrs.layout = options.layout
  }
  if (options.onFinish != null) {
    attrs.onFinish = options.onFinish
  }

  if (uiAttrs != null && uiAttrs.formAttrs != null) {
    attrs = {
      ...attrs,
      ...uiAttrs.formAttrs,
    }
  }

  if (options.formAttrs != null) {
    attrs = extend(attrs, options.formAttrs)
  }

  const labelComp = createTitleElem(uiProps, options)

  const nodeElems = childrenObj.getNodes()

  if ((labelComp == null || labelComp.title == null) && nodeElems.length === 0) {
    return null
  }

  return (
    <React.Fragment>
      {labelComp}
      <Form ref={formRef} {...attrs}>
        {childrenObj.getNodes()}
        {btnsUI}
      </Form>
    </React.Fragment>
  )
}

function createDetailElem(compInfo, reactObj, value, values) {
  const { options, origin: uiProps, value: val } = compInfo

  let children = ''

  if (compInfo.origin.mode === 'none' || compInfo.type === 'none') {
    return ''
  }

  if (compInfo.type === 'custom') {
    // custom type 추가.
    if (compInfo.origin.createDetailElem != null) {
      children = compInfo.origin.createDetailElem(compInfo, reactObj, value, NineGQLUI, values)
    } else {
      console.log('not fount - origin.createDetailElem')
    }
  } else if (compInfo.type === 'datetime-utc' || compInfo.type === 'datetime-ufw-utc') {
    children = val
  } else if (compInfo.type === 'datetime' || compInfo.type === 'datetime-ufw') {
    children = val
  } else if (compInfo.type === 'date' || compInfo.type === 'date-ufw') {
    children = val
  } else if (compInfo.type === 'date-utc' || compInfo.type === 'date-ufw-utc') {
    children = val
  } else if (compInfo.type === 'time' || compInfo.type === 'time-ufw') {
    children = val
  } else if (compInfo.type === 'time-utc' || compInfo.type === 'time-ufw-utc') {
    children = val
  } else if (compInfo.type === 'url') {
    children = (
      <a className="ngql-link" src={val} target="_blank" rel="noopener noreferrer">
        {val}
      </a>
    )
  } else if (compInfo.type === 'checkbox') {
    children = val ? 'true' : ' false'
  } else if (
    compInfo.type === 'select' ||
    compInfo.type === 'select-number' ||
    compInfo.type === 'radio'
  ) {
    const selectKey = val
    if (options.t && compInfo.origin.valueKeys != null) {
      children = options.t(compInfo.origin.valueKeys[selectKey])
    } else {
      children = compInfo.origin.values[selectKey]
    }
  } else if (compInfo.type === 'image') {
    const alt = options.t && uiProps.altKey ? options.t(uiProps.altKey) : uiProps.alt
    // const { src } = uiProps
    children = <img className="ngql-image" src={val} alt={alt} />
  } else if (compInfo.type === 'formattedtext') {
    children = <pre>{str(val)}</pre>
  } else if (
    compInfo.type === 'json' ||
    compInfo.type === 'json2' ||
    compInfo.type === 'long-json' ||
    compInfo.type === 'schema-json'
  ) {
    let obj = val

    if (typeof val === 'string') {
      const jsonVal = val != null && val.length > 0 ? val : '{}'
      obj = JSON.parse(jsonVal)
    }

    let jsonStr = JSON.stringify(obj, null, 2)

    let showBtn = ''

    if (
      compInfo.type === 'long-json' ||
      compInfo.type === 'schema-json' ||
      compInfo.type === 'json2'
    ) {
      const originStr = jsonStr

      const limit = (uiProps.lineLimit || 10) - 1
      const regex = new RegExp(`(((.*?)(\\r|\\n|\\r\\n)){0,${limit}}(.*)(\\r|\\n|\\r\\n)?)`)

      const found = jsonStr.match(regex)

      ;[jsonStr] = found

      if (originStr.length !== jsonStr.length) {
        const modalState = reactObj.state[`${compInfo.name}FieldModal`]
        const showModal = () => {
          const state = {}
          state[`${compInfo.name}FieldModal`] = true
          reactObj.setState(state)
        }
        const hideModal = () => {
          const state = {}
          state[`${compInfo.name}FieldModal`] = false
          reactObj.setState(state)
        }
        showBtn = (
          <React.Fragment>
            ...
            <br />
            <Button type="primary" onClick={showModal}>
              Show All
            </Button>
            <Modal
              className="code-view"
              title={compInfo.label}
              visible={modalState}
              onOk={hideModal}
              onCancel={hideModal}
              cancelButtonProps={{ style: { display: 'none' } }}
            >
              <pre>
                <code>{str(originStr)}</code>
              </pre>
            </Modal>
          </React.Fragment>
        )
      }
    }
    children = (
      <React.Fragment>
        <pre>
          <code>{str(jsonStr)}</code>
        </pre>
        {showBtn}
      </React.Fragment>
    )
  } else {
    children = str(val)
  }

  const descAttrs = createTitleAttrs(uiProps, options)

  // const afterText = compInfo.afterText ? compInfo.afterText : ''

  const comp = (
    <Descriptions.Item label={compInfo.label} key={compInfo.name} {...descAttrs}>
      {children}
    </Descriptions.Item>
  )

  return comp
}

function createDetailContainer(childrenObj, options, uiProps, uiAttrs) {
  let descAttrs = createTitleAttrs(uiProps, options)

  descAttrs = extend(descAttrs, uiAttrs)

  return (
    <Descriptions size="small" column={1} bordered {...descAttrs}>
      {childrenObj.getNodes()}
    </Descriptions>
  )
}

const getTableOnRow = (name, tableInfo, reactObj) => {
  return (record, rowIndex) => {
    let originOnRow = null
    let onRow = null
    if (reactObj != null) {
      const onRowFunc = reactObj[`onRow${name}`]
      if (onRowFunc != null) {
        originOnRow = onRowFunc(record, rowIndex)
      }
    }

    if (tableInfo.onRow != null) {
      onRow = tableInfo.onRow(record, rowIndex, reactObj)
    }

    const rowInfo = {}
    if (ngqlConfig.tableClickRange != null && ngqlConfig.tableClickRange > 0) {
      rowInfo.tableClickRange = ngqlConfig.tableClickRange
    }

    const onRowAttr = {
      onClick: (e) => {
        console.log(' - click ', e, record, rowIndex)

        if (rowInfo.tableClickRange > 0) {
          const diff = rowInfo.tableClickRange
          const cX = e.clientX
          const cY = e.clientY

          if (
            cX - diff > rowInfo.cX ||
            rowInfo.cX > cX + diff ||
            cY - diff > rowInfo.cY ||
            rowInfo.cY > cY + diff
          ) {
            e.preventDefault()
            e.stopPropagation()
            return
          }
        }

        // eslint-disable-next-line no-unreachable
        if (
          tableInfo.mobileInfo != null &&
          tableInfo.mobileInfo.foldable != null &&
          tableInfo.mobileInfo.foldable !== false
        ) {
          const { icon } = tableInfo.mobileInfo.foldable

          const targetPos = e.currentTarget.getBoundingClientRect()

          const top = targetPos.y + icon.top
          const bottom = targetPos.y + icon.top + icon.height

          const left = targetPos.right - icon.right - icon.width
          const right = targetPos.right - icon.right

          if (e.clientX > left && e.clientX < right && e.clientY > top && e.clientY < bottom) {
            const classes = e.currentTarget.className.split(' ')
            const idx = classes.indexOf('unfold')
            if (idx > -1) {
              classes.splice(idx, 1)
            } else {
              classes.push('unfold')
            }
            e.currentTarget.className = classes.join(' ')
            e.preventDefault()
            e.stopPropagation()
            return
          }
        }

        if (originOnRow != null) {
          if (originOnRow.onClick() === true) {
            return
          }
        }
        if (onRow != null) {
          onRow.onClick(e)
        }
      }, // click row
      // onDoubleClick: () => {}, // double click row
      // onContextMenu: () => {}, // right button click row
      // onMouseEnter: () => {}, // mouse enter row
      // onMouseLeave: () => {}, // mouse leave row
    }

    if (rowInfo.tableClickRange > 0) {
      rowInfo.cX = -1
      rowInfo.cY = -1
      onRowAttr.onMouseDown = (e) => {
        console.log(' - onMouseDown ', e, record, rowIndex)
        rowInfo.cX = e.clientX
        rowInfo.cY = e.clientY
      }
    }

    return onRowAttr
  }
}

const getTableAttrs = (node, props, tableInfo, columnInfo, dataSource, resObj) => {
  const columns = columnInfo.columns.map((oldColumn) => {
    const column = {
      ...oldColumn,
    }
    const oldRender = column.render
    column.render = (text, record) => {
      let columnKey = null
      if (Array.isArray(column.dataIndex)) {
        columnKey = column.dataIndex[column.dataIndex.length - 1]
      } else {
        columnKey = column.dataIndex
      }

      let { title } = column
      if (column.mobileTitle != null) {
        const tmp = removeTag(column.mobileTitle.trim())
        title = tmp.length > 0 ? tmp : null
      } else if (typeof column.title === 'string') {
        const tmp = removeTag(column.title.trim())
        title = tmp.length > 0 ? tmp : null
      }

      let textElem = text

      if (
        node.fields != null &&
        node.fields[columnKey] != null &&
        node.fields[columnKey].nineType === 'field'
      ) {
        textElem = NineGQLUI.generateDetailUIByKey(
          columnKey,
          node.fields[columnKey],
          record.node,
          props,
          resObj.reactObj,
          null,
        )

        textElem = textElem != null ? textElem.detail : text
      }

      const renderResult = oldRender(textElem, record)

      const oldElem =
        renderResult != null &&
        typeof renderResult !== 'string' &&
        React.isValidElement(renderResult) !== true
          ? renderResult.children
          : renderResult

      if (oldElem == null) {
        return ''
      }

      const titleElem =
        title != null ? (
          <div className="nine-column-title">{title} : </div>
        ) : (
          <div className="nine-column-title"> </div>
        )

      const newElem = (
        <div className="nine-table-item-column" key={`item-${columnKey}`}>
          {titleElem}
          <div className="nine-column-value">{oldElem}</div>
        </div>
      )

      if (renderResult.children != null) {
        renderResult.children = newElem
        return renderResult
      }

      return newElem
    }
    return column
  })

  const attrs = {
    onRow: getTableOnRow(resObj.name, tableInfo, resObj.reactObj),
    loading: resObj.getLoading(),
    columns,
    dataSource,
    rowKey: tableInfo.getKeyFunc,
  }

  if (columnInfo.rowSelection != null) {
    attrs.rowSelection = columnInfo.rowSelection
  }

  if (props.rowClassName != null) {
    attrs.rowClassName = props.rowClassName
  }

  const pageCtrl = resObj.getPageControl()

  const onLoadMore = () => {
    if (resObj.morePage != null) {
      resObj.morePage()
    }
  }

  if (pageCtrl === 'more') {
    const nodeData = resObj.getResult()[0]
    if (nodeData != null && nodeData.hasNext === true) {
      console.log(' more button ~~! ')
      const btnText = (tableInfo.moreBtnText != null) ? tableInfo.moreBtnText : 'loading more';
      const loadMore = (
        <div className="more-button-container">
          <Button onClick={onLoadMore}>{btnText}</Button>
        </div>
      )
      attrs.loadMore = loadMore
    }
    attrs.pagination = false
  } else if (pageCtrl !== false) {
    attrs.pagination = resObj.getPaginationInfo()
  } else {
    attrs.pagination = false
  }

  let clsNames = []
  if (props.classNames != null) {
    clsNames = clsNames.concat(props.classNames)
  }

  if (tableInfo.useMobileMode !== false) {
    // attrs.style = createCSSLabel(columns)
    clsNames.push('useMobileMode')
  }

  if (
    tableInfo.mobileInfo != null &&
    tableInfo.mobileInfo.foldable != null &&
    tableInfo.mobileInfo.foldable !== false
  ) {
    // attrs.style = createCSSLabel(columns)
    if (tableInfo.mobileInfo.foldable === true) {
      tableInfo.mobileInfo.foldable.icon = {}
    }

    const { icon } = tableInfo.mobileInfo.foldable
    const style = {}

    if (icon != null) {
      // 좌표 계산을 위해 초기값 설정 필요. 기본 CSS값과 동일하게 맞춰야 함.
      if (icon.width == null) {
        icon.width = 24
      }
      if (icon.height == null) {
        icon.height = 24
      }
      if (icon.top == null) {
        icon.top = 9
      }
      if (icon.right == null) {
        icon.right = 4
      }

      Object.entries(icon).forEach(([key, value]) => {
        style[`--foldicon-${key}`] = `${value}px`
      })
    }
    attrs.style = style
    clsNames.push('foldable')
  }

  if (clsNames.length > 0) {
    attrs.className = clsNames.join(' ')
  }

  if (resObj.getOnChange) {
    attrs.onChange = resObj.getOnChange()

    if (attrs.pagination != null) {
      delete attrs.pagination.onChange
    }
  }

  if (props.attrs != null) {
    extend(attrs, props.attrs)
  }

  return attrs
}

function createTableInfo(node, options, tableInfo, columnInfo, dataSource, resObj) {
  const attrs = getTableAttrs(node, options, tableInfo, columnInfo, dataSource, resObj)
  return attrs
}

function createTableElem(node, options, tableInfo, columnInfo, dataSource, resObj) {
  const { uiProps } = node
  const descElem = createTitleElem(uiProps, options)

  if (node.uiProps != null) {
    options.uiNodeProps = node.uiProps
  }

  const attrs = getTableAttrs(node, options, tableInfo, columnInfo, dataSource, resObj)

  const onSizeChange = (isMobileView) => {
    console.log(' -- onSizeChange - ', isMobileView)

    resObj.setMobileMode(isMobileView)

    if (resObj.movePage != null) {
      const defaultV = resObj.getDefaultVariable()
      const pageNum = resObj.getArgument(defaultV, 'pageNum', 1)

      resObj.movePage(pageNum)
    }
  }

  return (
    <DeviceSizeChecker onSizeChange={onSizeChange}>
      {descElem}
      <Table scroll={{ x: '100%' }} {...attrs} />
      {attrs.loadMore}
    </DeviceSizeChecker>
  )
}

const getListAttrs = (node, props, tableInfo, itemInfo, dataSource, resObj) => {
  const attrs = {
    loading: resObj.getLoading(),
    dataSource,
    rowKey: tableInfo.getKeyFunc,
  }

  const onLoadMore = () => {
    if (resObj.morePage != null) {
      resObj.morePage()
    }
  }

  const pageCtrl = resObj.getPageControl()

  if (pageCtrl === 'more') {
    // const nodeData = resObj.getResult()[0];
    console.log(' more button ~~! ')
    const btnText = (tableInfo.moreBtnText != null) ? tableInfo.moreBtnText : 'loading more';
    const loadMore = (
      <div className="more-button-container">
        <Button onClick={onLoadMore}>{btnText}</Button>
      </div>
    )

    attrs.loadMore = loadMore
    attrs.pagination = false
  } else if (pageCtrl !== false) {
    attrs.pagination = resObj.getPaginationInfo()
  } else {
    attrs.pagination = false
  }

  let clsNames = ['nine-list']
  if (props.classNames != null) {
    clsNames = clsNames.concat(props.classNames)
  }

  if (tableInfo.useMobileMode !== false) {
    clsNames.push('useMobileMode')
  }

  if (
    tableInfo.mobileInfo != null &&
    tableInfo.mobileInfo.foldable != null &&
    tableInfo.mobileInfo.foldable !== false
  ) {
    // attrs.style = createCSSLabel(columns)
    const { icon } = tableInfo.mobileInfo.foldable
    const style = {}

    if (icon != null) {
      Object.entries(icon).forEach((key, value) => {
        style[`--foldicon-${key}`] = `${value}px`
      })
    }
    attrs.style = style
    clsNames.push('foldable')
  }

  if (clsNames.length > 0) {
    attrs.className = clsNames.join(' ')
  }

  const onRowFunc = getTableOnRow(resObj.name, tableInfo, resObj.reactObj)

  const itemAttr = {}

  if (itemInfo.action != null) {
    itemAttr.action = itemInfo.action
  }

  if (itemInfo.header != null) {
    attrs.header = itemInfo.header
  } else if (itemInfo.header === undefined && itemInfo.columns != null) {
    let idx = 0
    const headerItems = itemInfo.columns.map((item) => {
      idx += 1

      const headerAttrs = {
        className: 'nine-list-header-column',
        key: `header-${idx}`,
      }

      const style = {}
      if (item.width != null) {
        if (typeof item.width === 'number') {
          style['--item-width'] = `${item.width}px`
        } else {
          style['--item-width'] = item.width
        }
      }

      if (item.align != null) {
        style['--item-align'] = item.align
      }

      headerAttrs.style = style

      return <div {...headerAttrs}>{item.title}</div>
    })

    attrs.header = <div className="nine-list-header">{headerItems}</div>

    attrs.footer = itemInfo.footer != null ? itemInfo.footer : <div className="nine-list-footer" />
  }

  let expandable = null
  if (props.attrs != null && props.attrs.expandable != null) {
    expandable = props.attrs.expandable
  }

  attrs.renderItem = (record) => {
    let itemElem = null

    if (itemInfo.render != null) {
      itemElem = itemInfo.render(record)
    } else {
      const items = itemInfo.columns.map((item) => {
        let text = record
        let columnKey = null
        if (Array.isArray(item.dataIndex)) {
          item.dataIndex.forEach((val) => {
            text = text[val]
          })
          columnKey = item.dataIndex[item.dataIndex.length - 1]
        } else {
          text = text[item.dataIndex]
          columnKey = item.dataIndex
        }
        const itemAttrs = {
          className: 'nine-list-item-column',
          key: `item-${columnKey}`,
        }

        const style = {}
        if (item.width != null) {
          if (typeof item.width === 'number') {
            style['--item-width'] = `${item.width}px`
          } else {
            style['--item-width'] = item.width
          }
        }

        if (item.align != null) {
          style['--item-align'] = item.align
        }

        itemAttrs.style = style

        let { title } = item
        if (item.mobileTitle != null) {
          const tmp = removeTag(item.mobileTitle.trim())
          title = tmp.length > 0 ? tmp : null
        } else if (typeof item.title === 'string') {
          const tmp = removeTag(item.title.trim())
          title = tmp.length > 0 ? tmp : null
        }

        const titleElem =
          title != null ? (
            <div className="nine-column-title">{title} : </div>
          ) : (
            <div className="nine-column-title"> </div>
          )

        return (
          <div {...itemAttrs}>
            {titleElem}
            <div className="nine-column-value">{item.render(text, record)}</div>
          </div>
        )
      })

      if (expandable != null && expandable.expandedRowRender != null) {
        const extItem = expandable.expandedRowRender(record)
        itemElem = (
          <React.Fragment>
            <div className="nine-list-item">{items}</div>
            <div className="nine-list-item-ext">{extItem}</div>
          </React.Fragment>
        )
      } else {
        itemElem = <div className="nine-list-item">{items}</div>
      }
    }

    const funcMap = onRowFunc(record, -1)
    itemAttr.onClick = (e) => {
      funcMap.onClick(e)
    }

    if (funcMap.onMouseDown != null) {
      itemAttr.onMouseDown = (e) => {
        funcMap.onMouseDown(e)
      }
    }

    return <List.Item {...itemAttr}>{itemElem}</List.Item>
  }

  if (props.attrs != null) {
    extend(attrs, props.attrs)
  }

  return attrs
}

function createListInfo(node, options, tableInfo, itemInfo, dataSource, resObj) {
  const attrs = getListAttrs(node, options, tableInfo, itemInfo, dataSource, resObj)
  return attrs
}

function createListElem(node, options, tableInfo, itemInfo, dataSource, resObj) {
  const { uiProps } = node
  const descElem = createTitleElem(uiProps, options)

  if (node.uiProps != null) {
    options.uiNodeProps = node.uiProps
  }

  const attrs = getListAttrs(node, options, tableInfo, itemInfo, dataSource, resObj)

  const onSizeChange = (isMobileView) => {
    console.log(' -- onSizeChange - ', isMobileView)

    resObj.setMobileMode(isMobileView)

    if (resObj.movePage != null) {
      const defaultV = resObj.getDefaultVariable()
      const pageNum = resObj.getArgument(defaultV, 'pageNum', 1)

      resObj.movePage(pageNum)
    }
  }

  return (
    <DeviceSizeChecker onSizeChange={onSizeChange}>
      {descElem}
      <List scroll={{ x: '100%' }} {...attrs} />
    </DeviceSizeChecker>
  )
}

function createTitleAttrs(uiProps, options) {
  let label = null
  if (uiProps != null) {
    label = options.t && uiProps.labelKey ? options.t(uiProps.labelKey) : uiProps.label
  }
  if (options.title != null) {
    label = options.title
  }

  let descAttrs = null
  if (label != null || options.extra != null || options.descAttrs != null) {
    descAttrs = {}
    if (label != null) {
      descAttrs.title = label
    }
    if (options.extra != null) {
      descAttrs.extra = options.extra
    }
    if (options.descAttrs != null) {
      extend(descAttrs, options.descAttrs)
    }
  }

  return descAttrs
}

function createTitleElem(uiProps, options) {
  const descAttrs = createTitleAttrs(uiProps, options)

  if (descAttrs != null) {
    return <Descriptions size="small" column={1} bordered {...descAttrs} />
  }
  return ''
}

function createAllElem(compInfo, uiAttrs) {
  const subElems = []

  if (compInfo.nodeInfo.list.length > 0) {
    Object.keys(compInfo.nodeInfo.map).forEach((key) => {
      if (compInfo.nodeInfo.map[key].isTable !== true) {
        const elem = compInfo.getElem(key, uiAttrs)
        if (elem != null) {
          subElems.push({
            key,
            elem,
          })
        }
      }
    })
  }

  const mainFields = compInfo.getElem(null, uiAttrs)

  const mainElem =
    mainFields != null ? (
      <React.Fragment>
        {mainFields}
        {/* <br /> */}
      </React.Fragment>
    ) : null

  return (
    <React.Fragment>
      {mainElem}
      {subElems.map((item /* , idx */) => {
        return (
          <React.Fragment key={item.key}>
            {/* idx > 0 ? <br /> : null */}
            <br />
            {item.elem}
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}

function valueSetFilter(isEdit, type, value) {
  // console.log(' --- vSetf : ', isEdit, type, value)
  if (isEdit === true) {
    // detail, edit에는 local time으로 표시.
    // 저장은 utc로
    if (type === 'datetime' || type === 'datetime-ufw') {
      return timeFormat(value, 'YYYY-MM-DDTHH:mm')
    }
    // detail, edit에는 utc time으로 표시.
    // 저장도 utc로
    if (type === 'datetime-utc' || type === 'datetime-ufw-utc') {
      return timeFormatUTC(value, 'YYYY-MM-DDTHH:mm')
    }

    if (type === 'date' || type === 'date-ufw') {
      return timeFormat(value, 'YYYY-MM-DD')
    }
    if (type === 'date-utc' || type === 'date-ufw-utc') {
      return timeFormatUTC(value, 'YYYY-MM-DD')
    }
    if (type === 'time' || type === 'time-ufw') {
      return timeFormat(value, 'HH:mm')
    }
    if (type === 'time-utc' || type === 'time-ufw-utc') {
      return timeFormatUTC(value, 'HH:mm')
    }
  } else {
    if (type === 'datetime' || type === 'datetime-ufw') {
      return `${timeFormatDefault(value)} ${timeFormat(value, 'ZZ')}`
    }
    if (type === 'datetime-utc' || type === 'datetime-ufw-utc') {
      return `${timeFormatUTCDefault(value)} ${timeFormatUTC(value, 'ZZ')}`
    }

    if (type === 'date' || type === 'date-ufw') {
      return timeFormat(value, 'YYYY-MM-DD ZZ')
    }
    if (type === 'date-utc' || type === 'date-ufw-utc') {
      return timeFormatUTC(value, 'YYYY-MM-DD ZZ')
    }
    if (type === 'time' || type === 'time-ufw') {
      return timeFormat(value, 'HH:mm ZZ')
    }
    if (type === 'time-utc' || type === 'time-ufw-utc') {
      return timeFormatUTC(value, 'HH:mm ZZ')
    }
  }
  return value
}

function valueGetFilter(isEdit, type, value) {
  // console.log(' --- vGetf : ', isEdit, type, value)
  if (isEdit === true) {
    if (type === 'datetime' || type === 'datetime-ufw') {
      return moment(value).utc().format('YYYY-MM-DDTHH:mm')
    }
    if (type === 'datetime-utc' || type === 'datetime-ufw-utc') {
      const time = value
      let utcTime = time
      // if (time.length != null && time.indexOf('T') > 0 && time.indexOf('Z') === -1) {
      if (time.length != null && time.indexOf('Z') === -1) {
        utcTime = `${time}Z`
      }
      return moment(utcTime).utc().format('YYYY-MM-DDTHH:mm')
    }

    if (type === 'date' || type === 'date-ufw') {
      return moment(value).utc().format('YYYY-MM-DDTHH:mm')
    }
    if (type === 'date-utc' || type === 'date-ufw-utc') {
      const time = `${value}T00:00:00`
      let utcTime = time
      // if (time.length != null && time.indexOf('T') > 0 && time.indexOf('Z') === -1) {
      if (time.length != null && time.indexOf('Z') === -1) {
        utcTime = `${time}Z`
      }
      return moment(utcTime).utc().format('YYYY-MM-DDTHH:mm')
    }
    if (type === 'time' || type === 'time-ufw') {
      return moment(value).utc().format('HH:mm')
    }
    if (type === 'time-utc' || type === 'time-ufw-utc') {
      const time = value
      let utcTime = time
      // if (time.length != null && time.indexOf('T') > 0 && time.indexOf('Z') === -1) {
      if (time.length != null && time.indexOf('Z') === -1) {
        utcTime = `${time}Z`
      }
      return moment(utcTime).utc().format('HH:mm')
    }
  }
  return value
}

const NineGQLUI = {
  getAttrs,
  createEditElem,
  createEditContainer,
  createDetailElem,
  createDetailContainer,
  createTableInfo,
  createTableElem,
  createListInfo,
  createListElem,
  createAllElem,
  valueSetFilter,
  valueGetFilter,
}

export default NineGQLUI
