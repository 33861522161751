import i18n from 'i18n'

export default function getMenuList() {
  const menuList = [
    // {
    //   title: i18n.t('dashboard.title'),
    //   // title: 'Dashboard',
    //   key: 'dashboardnine',
    //   url: '/dashboard',
    //   icon: 'fe fe-home',
    // },
    {
      title: i18n.t('workspace.title.detail'),
      // title: 'Workspace List',
      key: 'myWorkspace',
      url: '/myWorkspace',
      icon: 'fe fe-layers',
    },
    {
      title: i18n.t('user.title.list'),
      // title: 'User List',
      key: 'userList',
      url: '/users/list',
      icon: 'icmn fe fe-users',
    },
    {
      title: i18n.t('device.title.list'),
      // title: 'Workspace List',
      key: 'deviceList',
      url: '/device/list',
      icon: 'fe fe-smartphone',
    },
    // {
    //   title: i18n.t('chat.title.list'),
    //   // title: 'Preset List',
    //   key: 'chatList',
    //   url: '/chat/list',
    //   icon: 'fe fe-message-circle',
    // },
    // {
    //   category: true,
    //   title: i18n.t('menu.category.enterpriseMaster'),
    // },
    // {
    //   title: i18n.t('enterpriseSite.title.list'),
    //   // title: 'Workspace List',
    //   key: 'enterpriseSite',
    //   url: '/enterprise-site/list',
    //   icon: 'fe fe-briefcase',
    // },
    // {
    //   divider: true,
    // },
    // {
    //   title: i18n.t('sysInfo.title.detail'),
    //   // title: 'Workspace List',
    //   key: 'systemInfo',
    //   url: '/systemInfo',
    //   icon: 'fe fe-monitor',
    // },
  ]

  return menuList
}
